import React, { useEffect, useMemo, useState } from 'react';
import { ComplianceEditContainer } from './compliance-edit.style';
import { ATag, ProgressBar, TabPageContainer } from '../../ui';
import { EmploymentHistoryReferences } from './employment-history.component';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  ERoute,
} from '../../../utils';
import {
  Compliance,
  ComplianceMetaData,
  EComplianceName,
  HostedFile,
} from '../../../backend/careo-api';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RegistrationDocuments } from './registration-documents.component';
import { RightToWork } from './right-to-work.component';
import { CriminalRecordCheck } from './criminal-record.component';
import { ProfessionalRegistration } from './professional-registration.component';
import { IdBadge } from './id-badge.component';
import { OccupationHealthClearance } from './occupation-health-clearance';
import { MandatoryTraining } from './mandatory-training';

export const complianceTasksList = Object.values(EComplianceName);

type ComplianceEditProps = {
  compliancesList: Compliance[];
  selectedTab: Compliance | null;
  getComplianceList: () => void;
};

export const ComplianceEdit = ({
  compliancesList,
  selectedTab,
  getComplianceList,
}: ComplianceEditProps) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [compliance, setCompliance] = useState<Compliance>();
  const [documents, setDocuments] = useState<HostedFile[]>([]);

  const complianceCompletion = useMemo(() => {
    const total = compliancesList.filter((el) => el.isActive);
    const passed = total.filter((el) => el.isApproved);

    return {
      percentage: total.length > 0 ? (passed.length / total.length) * 100 : 0,
      passed: passed.length,
      total: total.length,
    };
  }, [compliancesList]);

  const getCandidateDocuments = () => {
    AxiosInstance.files
      .filesControllerFindCandidateDocs(id!)
      .then((response) => {
        setDocuments(response.data.items);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const onClickSubmit = async (
    id: string,
    values: ComplianceMetaData,
    aiCheck = false,
    successMessage = 'Compliance updated successfully',
  ) => {
    successMessage =
      aiCheck === true ? 'Validation is in process ...' : successMessage;

    await AxiosInstance.compliances
      .compliancesControllerUpdate(
        id,
        { validate: !!aiCheck },
        {
          metadata: {
            ...values,
          },
        },
      )
      .then(() => {
        getCandidateDocuments();
        getComplianceList();
        reFetchCompliance(id);
        toast.success(successMessage);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const reFetchCompliance = (id: string) => {
    AxiosInstance.compliances
      .compliancesControllerGetCompliance(id)
      .then((response) => {
        setCompliance(response.data);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const onUpdateValidation = (idCompliance: string, value: string) => {
    const isApproved = value === 'true' ? true : false;
    AxiosInstance.compliances
      .compliancesControllerUpdate(
        idCompliance,
        { validate: false },
        {
          isApproved,
        },
      )
      .then(() => {
        getComplianceList();
        toast.success('Compliance updated successfully');
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  useEffect(() => {
    if (selectedTab) setCompliance(selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    getCandidateDocuments();
  }, []);

  return (
    <ComplianceEditContainer data-testid="compliance-edit-container">
      <TabPageContainer data-testid="tab-page-container">
        <div className="compliance-completion">
          <ProgressBar value={complianceCompletion.percentage} />{' '}
          <div>
            &nbsp;&nbsp;&nbsp;&nbsp;
            {complianceCompletion.passed}/{complianceCompletion.total} Passed
          </div>
        </div>
        <div className="tab-items-list" data-testid="tab-items-list">
          {compliancesList.map((el) => (
            <ATag
              className={`tab-item ${el.taskName === compliance?.taskName && 'active'}`}
              onClick={() =>
                navigate(
                  `/${ERoute.CANDIDATES}/${id}/compliance/${el.taskName}`,
                )
              }
              key={el.taskName}
              data-testid={`tab-item-${el}`} // Added a unique test ID for each tab
            >
              {el.taskName}
            </ATag>
          ))}
        </div>
        <div
          className="tab-content-container"
          data-testid="tab-content-container"
        >
          {compliance?.taskName === EComplianceName.RegistrationDocuments && (
            <RegistrationDocuments
              compliance={compliance}
              documents={documents}
              onClickSubmit={onClickSubmit}
              getDocuments={getCandidateDocuments}
              reFetchCompliance={() => reFetchCompliance(compliance._id)}
              onUpdateValidation={onUpdateValidation}
              data-testid="registration-documents"
            />
          )}
          {compliance?.taskName ===
            EComplianceName.EmploymentHistoryReferences && (
            <EmploymentHistoryReferences
              compliance={compliance}
              documents={documents}
              onClickSubmit={onClickSubmit}
              getDocuments={getCandidateDocuments}
              reFetchCompliance={() => {
                reFetchCompliance(compliance._id);
              }}
              onUpdateValidation={onUpdateValidation}
              data-testid="employment-history"
            />
          )}
          {compliance?.taskName === EComplianceName.RightToWork && (
            <RightToWork
              compliance={compliance}
              documents={documents}
              onClickSubmit={onClickSubmit}
              getDocuments={getCandidateDocuments}
              reFetchCompliance={() => reFetchCompliance(compliance._id)}
              onUpdateValidation={onUpdateValidation}
              data-testid="right-to-work" // Added test ID for RightWork
            />
          )}
          {compliance?.taskName === EComplianceName.CriminalRecordCheck && (
            <CriminalRecordCheck
              compliance={compliance}
              documents={documents}
              onClickSubmit={onClickSubmit}
              getDocuments={getCandidateDocuments}
              reFetchCompliance={() => reFetchCompliance(compliance._id)}
              onUpdateValidation={onUpdateValidation}
              data-testid="right-to-work" // Added test ID for RightWork
            />
          )}
          {compliance?.taskName === EComplianceName.IDBadge && (
            <IdBadge
              compliance={compliance}
              documents={documents}
              onClickSubmit={onClickSubmit}
              getDocuments={getCandidateDocuments}
              reFetchCompliance={() => reFetchCompliance(compliance._id)}
              onUpdateValidation={onUpdateValidation}
              data-testid="right-to-work"
            />
          )}
          {compliance?.taskName ===
            EComplianceName.ProfessionalRegistration && (
            <ProfessionalRegistration
              compliance={compliance}
              documents={documents}
              onClickSubmit={onClickSubmit}
              getDocuments={getCandidateDocuments}
              reFetchCompliance={() => reFetchCompliance(compliance._id)}
              onUpdateValidation={onUpdateValidation}
              data-testid="professional-registration"
            />
          )}

          {compliance?.taskName ===
            EComplianceName.OccupationalHealthClearance && (
            <OccupationHealthClearance
              compliance={compliance}
              documents={documents}
              onClickSubmit={onClickSubmit}
              getDocuments={getCandidateDocuments}
              reFetchCompliance={() => reFetchCompliance(compliance._id)}
              onUpdateValidation={onUpdateValidation}
              data-testid="professional-registration"
            />
          )}

          {compliance?.taskName === EComplianceName.MandatoryTraining && (
            <MandatoryTraining
              compliance={compliance}
              documents={documents}
              onClickSubmit={onClickSubmit}
              getDocuments={getCandidateDocuments}
              reFetchCompliance={() => reFetchCompliance(compliance._id)}
              onUpdateValidation={onUpdateValidation}
              data-testid="occupational-health" // Added test ID for OccupationalHealth
            />
          )}
        </div>
      </TabPageContainer>
    </ComplianceEditContainer>
  );
};
