import React, { useEffect, useState } from 'react';
import { TitleCard, RadioGroup, Select, FieldText } from '../../ui';
import {
  Compliance,
  ComplianceFile,
  ComplianceMetaData,
  EVisaTimePeriod,
  HostedFile,
} from '../../../backend/careo-api';
import ComplianceUpload from './compliance-upload';

type EmploymentHistoryProps = {
  compliance: Compliance;
  documents: HostedFile[];
  onClickSubmit: (
    id: string,
    values: ComplianceMetaData,
    validate?: boolean,
    successMessage?: string,
  ) => void;
  getDocuments: () => void;
  reFetchCompliance?: () => void;
  onUpdateValidation: (id: string, value: string) => void;
};

type TFilesKey = 'passportFiles' | 'certificateFiles' | 'visaFiles';

type TAllFields =
  | TFilesKey
  | 'passport'
  | 'certificate'
  | 'hasWorkRight'
  | 'visaTimePeriod'
  | 'visaExpiryDate'
  | 'visaShareCode';

export const RightToWork = ({
  compliance,
  documents,
  onClickSubmit,
  getDocuments,
  reFetchCompliance,
  onUpdateValidation,
}: EmploymentHistoryProps) => {
  const [isApproved, setIsApproved] = useState<boolean>();
  const [passport, setPassport] = useState<string>();
  const [certificate, setCertificate] = useState<string>();
  const [hasWorkRight, setHasWorkRight] = useState<boolean>();
  const [visaTimePeriod, setVisaTimePeriod] = useState<EVisaTimePeriod>();
  const [visaExpiryDate, setVisaExpiryDate] = useState<string>();
  const [visaShareCode, setVisaShareCode] = useState<string>();

  const [filesList, setFilesList] = useState<
    { key: TFilesKey; data: ComplianceFile[] }[]
  >([
    { key: 'passportFiles', data: [] },
    { key: 'certificateFiles', data: [] },
    { key: 'visaFiles', data: [] },
  ]);

  const onUpdateCompliance = (
    key: TAllFields,
    value: any,
    successMessage?: string,
  ) => {
    onClickSubmit(compliance._id, { [key]: value }, false, successMessage);
  };

  useEffect(() => {
    if (compliance) {
      const passportFiles = compliance?.metadata?.passportFiles ?? [];
      const certificateFiles = compliance?.metadata?.certificateFiles ?? [];
      const visaFiles = compliance?.metadata?.visaFiles ?? [];
      setFilesList([
        { key: 'passportFiles', data: passportFiles },
        { key: 'certificateFiles', data: certificateFiles },
        { key: 'visaFiles', data: visaFiles },
      ]);
      setPassport(compliance.metadata?.passport);
      setCertificate(compliance.metadata?.certificate);
      setHasWorkRight(compliance.metadata?.hasWorkRight);
      setVisaTimePeriod(compliance.metadata?.visaTimePeriod);
      setVisaExpiryDate(compliance.metadata?.visaExpiryDate);
      setVisaShareCode(compliance.metadata?.visaShareCode);
      setIsApproved(compliance.isApproved);
    }
  }, [compliance]);

  return (
    <>
      <>
        <TitleCard data-testid="title-card-passportFiles">Passport</TitleCard>
        <RadioGroup
          name="passport"
          options={[
            { label: 'British Passport (incl N.Ireland)', value: 'British' },
            { label: 'Other', value: 'Other' },
          ]}
          value={passport}
          onChange={(value) => {
            onUpdateCompliance('passport', value);
          }}
          data-testid="passport-radio-group"
        />
        <ComplianceUpload
          fileKey="passportFiles"
          isMulti
          label="Upload Passport"
          description="Does it contain all required fields."
          getDocuments={getDocuments}
          reFetchCompliance={reFetchCompliance}
          filesList={filesList}
          setFilesList={setFilesList}
          onUpdateCompliance={onUpdateCompliance}
          documents={documents}
        />
      </>

      <>
        <TitleCard data-testid="title-card-passportFiles">
          Birth Certificate or Certificate of Naturalisation
        </TitleCard>
        <RadioGroup
          name="certificate"
          options={[
            { label: 'British Passport (incl N.Ireland)', value: 'British' },
            { label: 'Other', value: 'Other' },
          ]}
          value={certificate}
          onChange={(value: any) => {
            setCertificate(value);
            onUpdateCompliance('certificate', value);
          }}
          data-testid="certificate-radio-group"
        />
        <ComplianceUpload
          fileKey="certificateFiles"
          isMulti
          label="Upload Certificate"
          description="Does it contain all required fields."
          getDocuments={getDocuments}
          reFetchCompliance={reFetchCompliance}
          filesList={filesList}
          setFilesList={setFilesList}
          onUpdateCompliance={onUpdateCompliance}
          documents={documents}
        />
      </>

      <>
        <TitleCard data-testid="title-card-passportFiles">
          Right to Work
        </TitleCard>
        <RadioGroup
          name="hasWorkRight"
          options={[
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ]}
          value={hasWorkRight}
          onChange={(value) => {
            const newValue = value === 'true' ? true : false;
            setHasWorkRight(newValue);
            onUpdateCompliance('hasWorkRight', newValue);
          }}
          data-testid="certificate-radio-group"
        />
        <ComplianceUpload
          fileKey="visaFiles"
          isMulti
          label="Upload Visa"
          description="Does it contain all required fields."
          getDocuments={getDocuments}
          reFetchCompliance={reFetchCompliance}
          filesList={filesList}
          setFilesList={setFilesList}
          onUpdateCompliance={onUpdateCompliance}
          documents={documents}
        />
        <div className="row">
          <div className="col-md-4">
            <Select
              placeholder="Enter here ..."
              label="Time Period"
              required
              options={[
                { label: '1 Year', value: EVisaTimePeriod.Value1Year },
                {
                  label: 'Indefinite Leave to Remain',
                  value: EVisaTimePeriod.IndefiniteLeaveToRemain,
                },
              ]}
              value={visaTimePeriod}
              onChange={(value: any) => {
                setVisaTimePeriod(value);
                onUpdateCompliance('visaTimePeriod', value);
              }}
            />
          </div>
          <div className="col-md-4">
            <FieldText
              placeholder="Enter here ..."
              label="Expiry Date"
              required
              type="date"
              value={visaExpiryDate}
              onChange={(e) => {
                setVisaExpiryDate(e.target.value);
                onUpdateCompliance('visaExpiryDate', e.target.value);
              }}
              disabled={visaTimePeriod !== EVisaTimePeriod.Value1Year}
            />
          </div>
          <div className="col-md-4">
            <FieldText
              placeholder="Enter here ..."
              label="Share Code"
              required
              value={visaShareCode}
              onChange={(e) => {
                setVisaShareCode(e.target.value);
                onUpdateCompliance('visaShareCode', e.target.value);
              }}
            />
          </div>
        </div>
      </>

      {/* Approved Section */}
      <>
        <TitleCard data-testid="title-card-approved">Approved</TitleCard>
        <RadioGroup
          options={[
            {
              label: 'Pass',
              value: 'true',
            },
            {
              label: 'Fail',
              value: 'false',
            },
          ]}
          name="approved"
          value={isApproved?.toString()}
          onChange={(value: any) => onUpdateValidation(compliance._id, value)}
          data-testid="approved-radio-group"
        />
        {/* <div
          className="info-card compliance-status buttons-container validated-by-ai"
          data-testid="validate-by-ai-button-container"
        >
          <Button
            type="primary"
            onClick={() => onClickSubmit(compliance._id, {}, true)}
            data-testid="validate-by-ai-button"
          >
            Validate By AI
          </Button>
        </div> */}
      </>
    </>
  );
};
