import React from 'react';
import styled from 'styled-components';
import { Button, SubTitlePage, TitlePage } from '../ui';
import { useModal } from '../../contexts/side-modal.context';
import { AddComplianceForm } from './add-compliance-form';

const ComplianceStartContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
`;

type ComplianceStartProps = {
  getComplianceList: () => void;
};

export const ComplianceStart = ({
  getComplianceList,
}: ComplianceStartProps) => {
  const { openModal, closeModal } = useModal();

  const onClickStart = () => {
    openModal({
      title: 'Compliances',
      component: (
        <AddComplianceForm
          onCancel={() => closeModal()}
          onSuccess={() => {
            getComplianceList();
            closeModal();
          }}
        />
      ),
    });
  };

  return (
    <ComplianceStartContainer>
      <img src="/assets/img/no-item.png" width={200} />
      <br />
      <TitlePage>Start Compliance</TitlePage>
      <SubTitlePage>Please add new news by clicking "Start" below</SubTitlePage>
      <br />
      <Button type="success" onClick={onClickStart}>
        Start
      </Button>
    </ComplianceStartContainer>
  );
};
