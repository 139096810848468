import React, { ChangeEvent, useEffect, useState } from 'react';
import { FieldTextContainer } from './field-text.style';
import {
  FieldError,
  UseFormRegisterReturn,
  UseFormSetValue,
} from 'react-hook-form';
import { EyeIcon, EyeOffIcon } from '../../../../icons';

type FieldTextProps = {
  label?: string;
  type?:
    | 'text'
    | 'number'
    | 'date'
    | 'time'
    | 'phoneNumber'
    | 'email'
    | 'password'
    | 'currency';
  placeholder?: string;
  value?: string | number;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  register?: UseFormRegisterReturn<string>;
  className?: string;
  min?: number | string;
  max?: number | string;
  disabled?: boolean;
  error?: FieldError;
  warning?: string;
  step?: string | number;
  setValue?: UseFormSetValue<any>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement> | undefined;
  maxLength?: number;
};

export const FieldText = ({
  label,
  type = 'text',
  placeholder = '',
  required,
  onChange,
  value,
  register,
  className,
  max,
  min,
  disabled,
  error,
  warning,
  step,
  setValue,
  onKeyDown,
  maxLength,
}: FieldTextProps) => {
  useEffect(() => {
    if (type === 'phoneNumber' && register?.name) {
      setValue?.(register?.name, (value as string)?.replace(/[^+\d]/g, ''));
    }
    if (type === 'number' && register?.name) {
      console.log({ value });
      setValue?.(register?.name, Number(value));
    }
  }, [value]);

  const [passwordTextVisible, setPasswordTextVisible] = useState(false);

  return (
    <FieldTextContainer
      className={className}
      hasError={!!error}
      data-testid="field-text-container"
    >
      {label && (
        <label htmlFor="" data-testid="field-text-label">
          {label} {required && <span>*</span>}{' '}
          {
            <span style={{ color: 'orange', paddingLeft: '5px' }}>
              {warning}
            </span>
          }
        </label>
      )}

      <div className="input-container" data-testid="input-container">
        {type === 'currency' && <span className="currency-icon">£</span>}
        <input
          step={step}
          type={
            type === 'phoneNumber'
              ? 'text'
              : type === 'currency'
                ? 'number'
                : type === 'password' && passwordTextVisible
                  ? 'text'
                  : type
          }
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          min={min}
          max={max}
          {...register}
          disabled={disabled}
          onKeyDown={onKeyDown}
          maxLength={maxLength}
          data-testid="field-text-input"
        />
        {error && (
          <span className="error-container" data-testid="field-text-error">
            {error?.message}
          </span>
        )}
        {type === 'password' && (
          <span
            onClick={() => setPasswordTextVisible(!passwordTextVisible)}
            data-testid="password-visibility-toggle"
          >
            {passwordTextVisible ? <EyeIcon /> : <EyeOffIcon />}
          </span>
        )}
      </div>
    </FieldTextContainer>
  );
};
