import React, { useState } from 'react';
import { DownloadIcon, MinusIcon, PlusIcon } from '../../icons';
import {
  Compliance,
  ComplianceFile,
  EComplianceName,
  ECriminalRecordCheckType,
} from '../../backend/careo-api';
import { useParams } from 'react-router-dom';
import { Badge, Table } from '../ui';
import { downloadFile, formatDate } from '../../utils';

type ComplianceAuditProps = {
  compliancesList: Compliance[];
  getComplianceList?: () => Promise<void>;
};

const ItemAuditRow = ({
  compliance,
}: {
  compliance: Compliance;
  id: string;
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const subItems = (() => {
    switch (compliance.taskName) {
      case EComplianceName.RegistrationDocuments:
        return [
          {
            label: 'Application form',
            value: compliance.metadata?.applicationFormFile,
          },
          {
            label: 'Company Handbook',
            value: compliance.metadata?.handbookFile,
          },
        ];
      case EComplianceName.EmploymentHistoryReferences:
        return [
          {
            label: 'CV',
            value: compliance.metadata?.cvFile,
          },
          {
            label: 'References',
            value: compliance.metadata?.references,
          },
        ];
      case EComplianceName.RightToWork:
        return [
          {
            label: 'Passport',
            value: compliance.metadata?.passportFiles,
          },
          {
            label: 'Certificate',
            value: compliance.metadata?.certificateFiles,
          },
          {
            label: 'Visa',
            value: compliance.metadata?.visaFiles,
          },
        ];
      case EComplianceName.CriminalRecordCheck:
        if (
          compliance.metadata?.criminalRecordType ===
          ECriminalRecordCheckType.Dbs
        ) {
          return [
            {
              label: 'DBS Certificate',
              value: compliance.metadata?.dbsCertificateFile,
            },
          ];
        } else if (
          compliance.metadata?.criminalRecordType ===
          ECriminalRecordCheckType.Pvg
        ) {
          return [
            {
              label: 'PVG Certificate',
              value: compliance.metadata?.pvgCertificateFile,
            },
          ];
        } else {
          return [
            {
              label: 'Access NI Certificate',
              value: compliance.metadata?.niCertificateFile,
            },
          ];
        }

      case EComplianceName.IDBadge:
        return [
          {
            label: 'Profile Picture',
            value: compliance.metadata?.profilePictureFile,
          },
          {
            label: 'ID badge',
            value: compliance.metadata?.badgeFile,
          },
        ];
      case EComplianceName.ProfessionalRegistration:
        return [
          {
            label: 'Registration Certificates',
            value: compliance.metadata?.registrationCertificates,
          },
          {
            label: 'Registration Checks Files',
            value: compliance.metadata?.registrationChecksFiles,
          },
        ];
      case EComplianceName.OccupationalHealthClearance:
        return [
          {
            label: 'Fitness to work certificate',
            value: compliance.metadata?.fitnessToWorkCertificateFile,
          },
          {
            label: 'Bloods',
            value: compliance.metadata?.bloodsFile,
          },
          {
            label: 'Medical Questionnaire',
            value: compliance.metadata?.medicalQuestionnaireFile,
          },
        ];
      case EComplianceName.MandatoryTraining:
        return [
          {
            label: 'Training Certificate',
            value: compliance.metadata?.trainingCertificateFile,
          },
          {
            label: 'Coverage Certificate',
            value: compliance.metadata?.coverageCertificateFile,
          },
        ];
      default:
        return [];
    }
  })();

  return (
    <>
      <tr
        key={compliance._id}
        data-testid={`compliance-row-${compliance._id}`}
        onClick={() => setIsOpen((prev) => !prev)}
        className="audit-tr"
      >
        <td className="toggle-td">
          {isOpen ? <MinusIcon /> : <PlusIcon />}{' '}
          <label
            htmlFor={`task-checkbox-${compliance._id}`}
            data-testid={`task-label-${compliance._id}`}
          >
            {compliance.taskName}
          </label>
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      {isOpen && (
        <>
          {subItems.map((el) => {
            return <SubItemAuditRow metadataItem={el} />;
          })}
        </>
      )}
    </>
  );
};

const SubItemAuditRow = ({
  metadataItem,
}: {
  metadataItem: {
    label: string;
    value: ComplianceFile[] | undefined;
  };
}) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <>
      <tr className="audit-tr" onClick={() => setIsOpen((prev) => !prev)}>
        <td className="toggle-td">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {isOpen ? <MinusIcon /> : <PlusIcon />}{' '}
          <div className={`task-container`} data-testid={`task-container`}>
            <label htmlFor={`task-checkbox`} data-testid={`task-label`}>
              {metadataItem.label}
            </label>
          </div>
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      {isOpen && (
        <>
          {(metadataItem.value ?? [])
            .sort((a, b) => {
              if (a.archiveDate && b.archiveDate) {
                return (
                  new Date(b.archiveDate).getTime() -
                  new Date(a.archiveDate).getTime()
                );
              } else if (a.archiveDate) {
                return 1;
              } else if (b.archiveDate) {
                return -1;
              } else {
                return (
                  new Date(b.file.timestamp).getTime() -
                  new Date(a.file.timestamp).getTime()
                );
              }
            })
            ?.map((el, index) => {
              return (
                <tr
                  data-testid={`compliance-row-sub-item-${el.file._id}`}
                  key={index}
                >
                  <td>{el.file.fileName}</td>
                  <td>{formatDate(el.addedDate)}</td>
                  <td>
                    <Badge type={el.archiveDate ? 'info' : 'success'}>
                      {el.archiveDate ? 'Archive' : 'Active'}
                    </Badge>
                  </td>
                  <td>{el.archiveDate ? formatDate(el.archiveDate) : '-'}</td>
                  <td>{formatDate(el.file.timestamp)}</td>
                  <td>
                    <div className="action-item">
                      <div
                        className="download-icon"
                        onClick={() => downloadFile(el.file)}
                      >
                        <DownloadIcon />
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
        </>
      )}
    </>
  );
};

export const ComplianceAudit = ({ compliancesList }: ComplianceAuditProps) => {
  const { id } = useParams();

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>File Name</th>
            <th>Added Date</th>
            <th>Status</th>
            <th>Archived Date</th>
            <th>Uploaded Date</th>
            <th className="action-th"></th>
          </tr>
        </thead>
        <tbody>
          {compliancesList.length > 0 ? (
            <>
              {compliancesList.map((el) => {
                return <ItemAuditRow compliance={el} id={id!} />;
              })}
            </>
          ) : (
            <tr>
              <td
                colSpan={100}
                className="text-center"
                data-testid="no-item-found"
              >
                No item found
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};
