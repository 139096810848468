import React, { useEffect, useState } from 'react';
import { OfferContainer } from './offer.style';
import { AppIcon } from '../../icons';
import { Button, FieldText } from '../../components';
import { AxiosInstance, formatDate } from '../../utils';
import {
  Application,
  EApplicationApprovalStatus,
} from '../../backend/careo-api';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { formatCurrency } from '../../utils';
import { useAuth } from '../../contexts/auth.context';

const messagesRecord: Record<EApplicationApprovalStatus, string> = {
  [EApplicationApprovalStatus.NOT_ACTIVE]: 'Placement is not active',
  [EApplicationApprovalStatus.PENDING]: 'Placement is pending.',
  [EApplicationApprovalStatus.CLIENT_CONFIRMED]:
    'Placement is confirmed by client.',
  [EApplicationApprovalStatus.CLIENT_REJECTED]:
    'Placement is rejected by client.',
  [EApplicationApprovalStatus.CANDIDATE_CONFIRMED]:
    'Placement is confirmed by candidate. Please review the submitted placement below.',
  [EApplicationApprovalStatus.CANDIDATE_REJECTED]:
    'Placement is rejected by candidate.',
  [EApplicationApprovalStatus.SYSTEM_CANCELLED]:
    'Placement is automatically cancelled',
  [EApplicationApprovalStatus.RECRUITER_APPROVED]:
    'Placement is already approved.',
  [EApplicationApprovalStatus.RECRUITER_DECLINED]:
    'Placement is already declined.',
  [EApplicationApprovalStatus.RECRUITER_CANCELED]:
    'Placement is cancelled by recruiter.',
  [EApplicationApprovalStatus.RECRUITER_STOPPED]:
    'Placement is stopped by recruiter.',
};

const OfferRecruiterPage = () => {
  const navigate = useNavigate();
  const { placementId } = useParams();
  const { user } = useAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [placement, setPlacement] = useState<Application | null>(null);

  const getOfferDetails = async () => {
    try {
      const result = (
        await AxiosInstance.applications.applicationsControllerGetApplicationById(
          placementId!,
        )
      ).data;
      setPlacement(result);
    } catch (error) {
      toast.error('Something went wrong');
    }
    setIsLoading(false);
  };

  const onClickApprove = async () => {
    try {
      await AxiosInstance.placementMember.placementMemberControllerRecruiterApproveByPlacementId(
        placementId!,
      );
      toast.success('Offer approved successfully');
      getOfferDetails();
    } catch (error) {
      toast.error('Something went wrong. Please verify the fields');
    }
  };

  const onClickReject = async () => {
    try {
      await AxiosInstance.placementMember.placementMemberControllerRecruiterRejectByPlacementId(
        placementId!,
      );
      toast.success('Offer rejected successfully');
      getOfferDetails();
    } catch (error) {
      toast.error('Something went wrong. Please verify the fields');
    }
  };

  useEffect(() => {
    if (!user) {
      navigate('/login');
    } else {
      getOfferDetails();
    }
  }, []);

  if (!user) {
    return null;
  }

  if (isLoading) {
    return <>loading ...</>;
  }

  if (!placement) {
    return <>Offer not found</>;
  }

  return (
    <OfferContainer data-testid="offer-container">
      <div
        className="content container"
        id="container"
        data-testid="content-container"
      >
        <div className="logo-container" data-testid="logo-container">
          <AppIcon />
        </div>

        <div className="title" data-testid="offer-title">
          {messagesRecord[placement.approvalStatus!] ?? ''}
        </div>

        <FieldText
          data-testid="placement-field-text"
          label="Placement"
          value={
            placement?.job.client.clientName + ' - ' + placement?.job.specialty
          }
          disabled
        />
        <div className="subtitle" data-testid="placement-details-subtitle">
          Placement Details
        </div>
        <div
          className="personal-info-container"
          data-testid="personal-info-container"
        >
          <div className="personal-name" data-testid="personal-name">
            {placement?.candidate?.title} {placement?.candidate?.firstName}{' '}
            {placement?.candidate?.lastName}
          </div>
          <div className="row" data-testid="personal-info-row">
            <div className="col-md-6 row" data-testid="specialty-row">
              <div
                className="info-item-title col-5"
                data-testid="specialty-title"
              >
                Specialty
              </div>
              <div
                className="info-item-data col-7"
                data-testid="specialty-data"
              >
                {placement?.candidate?.designation || '-'}
              </div>
            </div>
            <div className="col-md-6 row" data-testid="department-row">
              <div
                className="info-item-title col-5"
                data-testid="department-title"
              >
                Department
              </div>
              <div
                className="info-item-data col-7"
                data-testid="department-data"
              >
                {placement?.candidate?.departments?.join(',') || '-'}
              </div>
            </div>
            <div className="col-md-6 row" data-testid="week-commencing-row">
              <div
                className="info-item-title col-5"
                data-testid="week-commencing-title"
              >
                Week Commencing
              </div>
              <div
                className="info-item-data col-7"
                data-testid="week-commencing-data"
              >
                {formatDate(placement?.availableFrom ?? '')}
              </div>
            </div>
            <div className="col-md-6 row" data-testid="team-row">
              <div className="info-item-title col-5" data-testid="team-title">
                Team
              </div>
              <div className="info-item-data col-7" data-testid="team-data">
                {placement?.job.specialty}
              </div>
            </div>
            <div className="col-md-6 row" data-testid="email-row">
              <div className="info-item-title col-5" data-testid="email-title">
                Email Address
              </div>
              <div className="info-item-data col-7" data-testid="email-data">
                {placement?.candidate.email}
              </div>
            </div>
            <div className="col-md-6 row" data-testid="grade-row">
              <div className="info-item-title col-5" data-testid="grade-title">
                Grade
              </div>
              <div className="info-item-data col-7" data-testid="grade-data">
                {placement?.job?.grade}
              </div>
            </div>
            <div className="col-md-6 row" data-testid="start-date-row">
              <div
                className="info-item-title col-5"
                data-testid="start-date-title"
              >
                Start Date
              </div>
              <div
                className="info-item-data col-7"
                data-testid="start-date-data"
              >
                {formatDate(placement.availableFrom)}
              </div>
            </div>
            <div className="col-md-6 row" data-testid="end-date-row">
              <div
                className="info-item-title col-5"
                data-testid="end-date-title"
              >
                End Date
              </div>
              <div className="info-item-data col-7" data-testid="end-date-data">
                {formatDate(placement.availableTo)}
              </div>
            </div>
            <div className="col-md-6 row" data-testid="pay-amount-row">
              <div
                className="info-item-title col-5"
                data-testid="pay-amount-title"
              >
                Core Rate
              </div>
              <div
                className="info-item-data col-7"
                data-testid="pay-amount-data"
              >
                {formatCurrency(placement.coreRate)} per hour
              </div>
            </div>
            <div className="col-md-6 row" data-testid="pay-amount-row">
              <div
                className="info-item-title col-5"
                data-testid="pay-amount-title"
              >
                Unsocial Rate
              </div>
              <div
                className="info-item-data col-7"
                data-testid="pay-amount-data"
              >
                {formatCurrency(placement.unSocialRate)} per hour
              </div>
            </div>
            <div className="col-md-6 row" data-testid="on-call-rate-row">
              <div
                className="info-item-title col-5"
                data-testid="on-call-rate-title"
              >
                On Call Active Rate
              </div>
              <div
                className="info-item-data col-7"
                data-testid="on-call-rate-data"
              >
                {formatCurrency(placement.onCallActiveRate)} per hour
              </div>
            </div>

            <div className="col-md-6 row" data-testid="on-call-rate-row">
              <div
                className="info-item-title col-5"
                data-testid="on-call-rate-title"
              >
                On Call Passive Rate
              </div>
              <div
                className="info-item-data col-7"
                data-testid="on-call-rate-data"
              >
                {formatCurrency(placement.onCallPassiveRate)} per hour
              </div>
            </div>
            <div className="col-md-6 row" data-testid="charge-amount-row">
              <div
                className="info-item-title col-5"
                data-testid="charge-amount-title"
              >
                Charge Amount
              </div>
              <div
                className="info-item-data col-7"
                data-testid="charge-amount-data"
              >
                {formatCurrency(placement.fee)} per hour
              </div>
            </div>
            <div className="col-md-6 row" data-testid="on-call-charge-row">
              <div
                className="info-item-title col-5"
                data-testid="on-call-charge-title"
              >
                On Call Charge
              </div>
              <div
                className="info-item-data col-7"
                data-testid="on-call-charge-data"
              >
                {formatCurrency(placement.onCallFee)} per hour
              </div>
            </div>
            <div className="col-md-6 row" data-testid="hours-per-week-row">
              <div
                className="info-item-title col-5"
                data-testid="hours-per-week-title"
              >
                Hours per week
              </div>
              <div
                className="info-item-data col-7"
                data-testid="hours-per-week-data"
              >
                {placement.job?.hoursPerWeek}{' '}
              </div>
            </div>
          </div>
        </div>
      </div>
      {placement?.approvalStatus ===
        EApplicationApprovalStatus.CANDIDATE_CONFIRMED && (
        <div
          className="action-container container"
          data-testid="action-container"
        >
          <div className="left-container"></div>
          <div className="right-container">
            <Button
              data-testid="reject-button"
              type="primary"
              onClick={onClickReject}
            >
              Reject
            </Button>
            <Button
              data-testid="approve-button"
              type="primary"
              onClick={onClickApprove}
            >
              Approve
            </Button>
          </div>
        </div>
      )}
    </OfferContainer>
  );
};

export default OfferRecruiterPage;
