import React, { useEffect, useState } from 'react';
import {
  TitleCard,
  RadioGroup,
  Button,
  Switch,
  Select,
  FieldText,
} from '../../ui';
import {
  Compliance,
  ComplianceFile,
  ComplianceMetaData,
  ECriminalRecordCheckType,
  HostedFile,
} from '../../../backend/careo-api';
import ComplianceUpload from './compliance-upload';

type CriminalRecordCheckProps = {
  compliance: Compliance;
  documents: HostedFile[];
  onClickSubmit: (
    id: string,
    values: ComplianceMetaData,
    validate?: boolean,
    successMessage?: string,
  ) => void;
  getDocuments: () => void;
  reFetchCompliance?: () => void;
  onUpdateValidation: (id: string, value: string) => void;
};

type TFilesKey =
  | 'dbsCertificateFile'
  | 'pvgCertificateFile'
  | 'niCertificateFile';

type TAllFields =
  | TFilesKey
  | 'typeCheck'
  | 'criminalRecordType'
  | 'onDbsUpdateService'
  | 'isPerformCheckActivated'
  | 'dbsTimePeriod'
  | 'dbsCustomDate';

export const CriminalRecordCheck = ({
  compliance,
  documents,
  onClickSubmit,
  getDocuments,
  reFetchCompliance,
  onUpdateValidation,
}: CriminalRecordCheckProps) => {
  const [isApproved, setIsApproved] = useState<boolean>();
  const [typeCheck, setTypeCheck] = useState<string>();

  const [criminalRecordType, setCriminalRecordType] =
    useState<ECriminalRecordCheckType>();

  const [onDbsUpdateService, setOnDbsUpdateService] = useState<boolean>();
  const [isPerformCheckActivated, setIsPerformCheckActivated] =
    useState<boolean>();
  const [dbsTimePeriod, setDbsTimePeriod] = useState<string>();
  const [dbsCustomDate, setDbsCustomDate] = useState<string>();

  const [filesList, setFilesList] = useState<
    { key: TFilesKey; data: ComplianceFile[] }[]
  >([
    { key: 'dbsCertificateFile', data: [] },
    { key: 'pvgCertificateFile', data: [] },
    { key: 'niCertificateFile', data: [] },
  ]);

  const onUpdateCompliance = (
    key: TAllFields,
    value: any,
    successMessage?: string,
  ) => {
    onClickSubmit(
      compliance._id,
      {
        [key]: value,
      },
      false,
      successMessage,
    );
  };

  useEffect(() => {
    if (compliance?._id) {
      setTypeCheck(compliance.metadata?.typeCheck);
      setCriminalRecordType(compliance.metadata?.criminalRecordType);
      setDbsTimePeriod(compliance.metadata?.dbsTimePeriod);
      setDbsCustomDate(compliance.metadata?.dbsCustomDate);
      setIsPerformCheckActivated(
        compliance.metadata?.isPerformCheckActivated ?? false,
      );
      setOnDbsUpdateService(compliance.metadata?.onDbsUpdateService ?? false);
      setFilesList([
        {
          key: 'dbsCertificateFile',
          data: compliance?.metadata?.dbsCertificateFile ?? [],
        },
        {
          key: 'niCertificateFile',
          data: compliance?.metadata?.niCertificateFile ?? [],
        },
        {
          key: 'pvgCertificateFile',
          data: compliance?.metadata?.pvgCertificateFile ?? [],
        },
      ]);
      setIsApproved(compliance.isApproved);
    }
  }, [compliance]);

  return (
    <>
      <>
        <TitleCard data-testid="title-card-application-form-certificate">
          Type of Record
        </TitleCard>
        <RadioGroup
          options={[
            {
              label: 'External',
              value: 'External',
            },
            {
              label: 'Internal',
              value: 'Internal',
            },
          ]}
          name="typeCheck"
          value={typeCheck}
          onChange={(value: any) => {
            setTypeCheck(value);
            onUpdateCompliance('typeCheck', value);
          }}
        />
        <RadioGroup
          options={[
            {
              label: 'DBS',
              value: ECriminalRecordCheckType.Dbs,
            },
            {
              label: 'PVG (Scotland)',
              value: ECriminalRecordCheckType.Pvg,
            },
            {
              label: 'Access NI (Northern Ireland)',
              value: ECriminalRecordCheckType.Ni,
            },
          ]}
          name="criminalRecordType"
          value={criminalRecordType}
          onChange={(value: any) => {
            setCriminalRecordType(value);
            onUpdateCompliance('criminalRecordType', value);
          }}
        />
      </>

      {criminalRecordType === ECriminalRecordCheckType.Dbs && (
        <>
          <TitleCard data-testid="title-card-application-form-certificate">
            DBS
          </TitleCard>
          <ComplianceUpload
            fileKey="dbsCertificateFile"
            label="Upload enhanced DBS certificate "
            description=""
            getDocuments={getDocuments}
            reFetchCompliance={reFetchCompliance}
            filesList={filesList}
            setFilesList={setFilesList}
            onUpdateCompliance={onUpdateCompliance}
            documents={documents}
          />
          <div className="question-toggle-container">
            <div className="label">Is it on the DBS update service </div>
            <div>{onDbsUpdateService ? 'Yes' : 'No'}</div>
            <div className="action-toggle">
              <Switch
                value={onDbsUpdateService ?? false}
                onClick={() => {
                  onUpdateCompliance('onDbsUpdateService', !onDbsUpdateService);
                  setOnDbsUpdateService((prev) => !prev);
                }}
              />
            </div>
          </div>
          <div className="perform-check-container">
            <Switch
              value={isPerformCheckActivated ?? false}
              onClick={() => {
                onUpdateCompliance(
                  'isPerformCheckActivated',
                  !isPerformCheckActivated,
                );
                setIsPerformCheckActivated((prev) => !prev);
              }}
            />
            <div>Perform update check via government gateway </div>
          </div>
        </>
      )}

      {criminalRecordType === ECriminalRecordCheckType.Pvg && (
        <>
          <TitleCard data-testid="title-card-application-form-certificate">
            PVG (Scotland)
          </TitleCard>
          <ComplianceUpload
            fileKey="pvgCertificateFile"
            label="Upload PVG certificate"
            description=""
            getDocuments={getDocuments}
            reFetchCompliance={reFetchCompliance}
            filesList={filesList}
            setFilesList={setFilesList}
            onUpdateCompliance={onUpdateCompliance}
            documents={documents}
          />
        </>
      )}

      {criminalRecordType === ECriminalRecordCheckType.Ni && (
        <>
          <TitleCard data-testid="title-card-application-form-certificate">
            Access NI (Northern Ireland)
          </TitleCard>
          <ComplianceUpload
            fileKey="niCertificateFile"
            label="Upload Access NI certificate"
            description=""
            getDocuments={getDocuments}
            reFetchCompliance={reFetchCompliance}
            filesList={filesList}
            setFilesList={setFilesList}
            onUpdateCompliance={onUpdateCompliance}
            documents={documents}
          />
        </>
      )}

      <>
        <TitleCard data-testid="title-card-application-form-certificate">
          Expiry Date
        </TitleCard>
        <div className="row">
          <div className="col-md-6">
            <Select
              placeholder="Enter here ..."
              label="Time Period"
              required
              options={[{ label: '1 Year', value: '1 Year' }]}
              value={dbsTimePeriod}
              onChange={(value: any) => {
                setDbsTimePeriod(value);
                onUpdateCompliance('dbsTimePeriod', value);
              }}
            />
          </div>
          <div className="col-md-6">
            <FieldText
              placeholder="Enter here ..."
              label="Expiry Date"
              required
              type="date"
              value={dbsCustomDate}
              onChange={(e) => {
                setDbsCustomDate(e.target.value);
                onUpdateCompliance('dbsCustomDate', e.target.value);
              }}
            />
          </div>
        </div>
      </>

      {/* Approved Section */}
      {}
      <>
        <TitleCard data-testid="title-card-approved">Approved</TitleCard>
        <RadioGroup
          options={[
            {
              label: 'Pass',
              value: 'true',
            },
            {
              label: 'Fail',
              value: 'false',
            },
          ]}
          name="approved"
          value={isApproved?.toString()}
          onChange={(value: any) => onUpdateValidation(compliance._id, value)}
          data-testid="approved-radio-group"
        />
        {criminalRecordType === ECriminalRecordCheckType.Dbs && (
          <div
            className="info-card compliance-status buttons-container validated-by-ai"
            data-testid="validate-by-ai-button-container"
          >
            <Button
              type="primary"
              onClick={() => onClickSubmit(compliance._id, {}, true)}
              data-testid="validate-by-ai-button"
            >
              Validate By AI
            </Button>
          </div>
        )}
      </>
    </>
  );
};
