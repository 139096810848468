import React, { useEffect, useMemo, useState } from 'react';
import { Abbreviation, Badge, Button, FormContainer, TitleCard } from '../ui';
import {
  ELeadStatus,
  ELeadTag,
  ELeadType,
  HostedFile,
  Lead,
} from '../../backend/careo-api';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  formatCurrency,
  formatDate,
} from '../../utils';
import { toast } from 'react-toastify';
import { monthsList, TUIType } from '../../constants';
import { PlusIcon } from '../../icons';
import { calculateProgressLead } from './lead-form';
import { useModal } from '../../contexts/side-modal.context';
import { DocumentsListComponent } from '../document';

type ViewLeadProps = {
  lead: Lead;
};

export const leadStatusValueType = (value: ELeadStatus): TUIType => {
  switch (value) {
    case ELeadStatus.NewLead:
      return 'neutral';
    case ELeadStatus.ScopingPhase:
      return 'info';
    case ELeadStatus.ProposalSent:
      return 'info';
    case ELeadStatus.ContractSigned:
      return 'success';
    case ELeadStatus.EarlyPlanning:
      return 'warning';
    case ELeadStatus.AdvancedPlanning:
      return 'warning';
    case ELeadStatus.OperationalGoLive:
      return 'success';
    default:
      return 'primary';
  }
};

export const leadTagValueType = (value: ELeadTag): TUIType => {
  switch (value) {
    case ELeadTag.Live:
      return 'success';
    case ELeadTag.NonProgressive:
      return 'warning';
    case ELeadTag.Converted:
      return 'info';
    default:
      return 'info';
  }
};

export const leadTypeValueType = (value: ELeadType): TUIType => {
  switch (value) {
    case ELeadType.New:
      return 'info';
    case ELeadType.Extension:
      return 'neutral';
    default:
      return 'info';
  }
};

export const ViewLead = ({ lead }: ViewLeadProps) => {
  const [documents, setDocuments] = useState<HostedFile[]>([]);
  const { closeModal } = useModal();

  const calculateProgress = useMemo(() => {
    if (!lead) return 0;
    return calculateProgressLead(lead);
  }, [lead]);

  const getLeadDocuments = () => {
    AxiosInstance.files
      .filesControllerFindLeadDocuments(lead._id!)
      .then((result) => {
        setDocuments(result.data);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const onClickDelete = async (documentId: string) => {
    AxiosInstance.files
      .filesControllerDeleteFile(documentId)
      .then(() => {
        toast.success('File deleted successfully');
        getLeadDocuments();
      })
      .catch(() => {
        toast.error('Failed to delete file');
      });
  };

  useEffect(() => {
    getLeadDocuments();
  }, []);

  return (
    <FormContainer className="view-form" data-testid="lead-overview-form">
      <TitleCard data-testid="lead-overview-title">Overview</TitleCard>
      <div className="row">
        <div className="col-12" data-testid="lead-date-added">
          <div className="data-title">Date Added</div>
          <div className="data-value">{formatDate(lead.createdAt ?? '')}</div>
        </div>
        <div className="col-3" data-testid="lead-status">
          <div className="data-title">Status</div>
          <div className="data-value">
            <Badge type={leadStatusValueType(lead.status)}>{lead.status}</Badge>
          </div>
        </div>
        <div className="col-3" data-testid="lead-tag">
          <div className="data-title">Tag</div>
          <div className="data-value">
            <Badge type={leadTagValueType(lead.tag)}>{lead.tag}</Badge>
          </div>
        </div>
        <div className="col-3" data-testid="lead-type">
          <div className="data-title">Type</div>
          <div className="data-value">
            <Badge type={leadTypeValueType(lead.type)}>{lead.type}</Badge>
          </div>
        </div>
        <div className="col-3" data-testid="lead-region">
          <div className="data-title">Region</div>
          <div className="data-value">
            <Badge type="neutral">{lead.region}</Badge>
          </div>
        </div>
        <div className="col-3" data-testid="lead-trust-hospital">
          <div className="data-title">Trust/Hospital</div>
          <div className="data-value">
            <Badge type="neutral">{lead.trust?.name ?? '-'}</Badge>
          </div>
        </div>
        <div className="col-6" data-testid="lead-specialty">
          <div className="data-title">Specialty</div>
          <div className="data-value">
            <Badge type="neutral">{lead.specialty}</Badge>
          </div>
        </div>
        <div className="col-3" data-testid="lead-strength">
          <div className="data-title">Strength</div>
          <div className="data-value">
            <Badge type="info">{lead.strength}</Badge>
          </div>
        </div>
        <div className="col-12" data-testid="lead-cpms">
          <div className="data-title">CPMs</div>
          <div className="data-value cpms-list">
            {lead.cpms.map((el) => (
              <Badge type="neutral" key={`${el.firstName}-${el.lastName}`}>
                <Abbreviation>
                  {el.firstName[0]}
                  {el.lastName[0]}
                </Abbreviation>
                {el.firstName} {el.lastName}
              </Badge>
            ))}
          </div>
        </div>
        <div className="col-12" data-testid="lead-overview">
          <div className="data-title">Overview</div>
          <div className="data-value">{lead.overview}</div>
        </div>
        <div className="col-12" data-testid="lead-resource-requirement">
          <div className="data-title">Resource Requirement</div>
          <div className="data-value">{lead.resourceRequirement}</div>
        </div>
        <div className="col-3" data-testid="lead-starting-month">
          <div className="data-title">Starting Month</div>
          <div className="data-value">
            {monthsList.find((el) => el.value === lead.startingMonth)?.label}
          </div>
        </div>
        <div className="col-3" data-testid="lead-duration">
          <div className="data-title">Duration</div>
          <div className="data-value">
            {lead.durationValue} ({lead.durationType})
          </div>
        </div>
      </div>

      <TitleCard data-testid="lead-financial-title">Financial</TitleCard>
      <div className="row">
        <div className="col-3" data-testid="lead-revenue">
          <div className="data-title">Revenue</div>
          <div className="data-value">
            {lead.revenue ? formatCurrency(lead.revenue) : '-'}
          </div>
        </div>
        <div className="col-3" data-testid="lead-cost">
          <div className="data-title">Cost</div>
          <div className="data-value">
            {lead.cost ? formatCurrency(lead.cost) : '-'}
          </div>
        </div>
        <div className="col-3" data-testid="lead-margin">
          <div className="data-title">Margin</div>
          <div className="data-value">
            {lead.margin ? formatCurrency(lead.margin) : '-'} (**%)
          </div>
        </div>
        <div className="col-3" data-testid="lead-cashflow-benchmark">
          <div className="data-title">Cashflow Benchmark **%</div>
          <div className="data-value">
            {lead.benchmark ? formatCurrency(lead.benchmark) : '-'}
          </div>
        </div>
      </div>

      <TitleCard data-testid="lead-legal-title">Legal</TitleCard>
      <div className="row">
        <div className="col-12" data-testid="lead-legal-contact">
          <div className="data-title">Contact</div>
          <div className="data-value">
            {lead.legalContact ? (
              <>
                {lead.legalContact.firstName} {lead.legalContact.lastName}
              </>
            ) : (
              <>Unassigned</>
            )}
          </div>
        </div>
        <div className="col-12" data-testid="lead-contracts-documents">
          <div className="data-title">Contracts & documents</div>
          <DocumentsListComponent
            documents={documents}
            getDocuments={getLeadDocuments}
            onClickDelete={onClickDelete}
          />
        </div>
      </div>

      <div className="form-actions" data-testid="form-actions">
        <Button type="danger" variant="outlined" onClick={() => closeModal()}>
          Close
        </Button>
        <Button
          onClick={() => {}}
          type="success"
          variant="outlined"
          disabled={calculateProgress !== 100}
        >
          <PlusIcon /> Create Project from Lead
        </Button>
      </div>
    </FormContainer>
  );
};
