import React, { useEffect, useState } from 'react';
import { TitleCard, RadioGroup, Switch } from '../../ui';
import {
  Compliance,
  ComplianceFile,
  ComplianceMetaData,
  HostedFile,
} from '../../../backend/careo-api';
import ComplianceUpload from './compliance-upload';

type MandatoryTrainingProps = {
  compliance: Compliance;
  documents: HostedFile[];
  onClickSubmit: (
    id: string,
    values: ComplianceMetaData,
    validate?: boolean,
    successMessage?: string,
  ) => void;
  getDocuments: () => void;
  reFetchCompliance?: () => void;
  onUpdateValidation: (id: string, value: string) => void;
};

type TFilesKey = 'trainingCertificateFile' | 'coverageCertificateFile';
type TAllFields = TFilesKey | 'trainingList';

const trainingOptions = [
  'MT - Anaphylaxis',
  'MT - Blood Component Transfusion',
  'MT - Communication',
  'MT - Complaints Handling',
  'MT - Conflict Resolution',
  'MT - Consent',
  'MT - Counter Fraud',
  'MT - Dementia Awareness',
  'MT - Duty of Care',
  'MT - Dysphagia Care',
  'MT - Epilepsy Awareness',
  'MT - Equality & Diversity',
  'MT - Fire Safety Theory',
  'MT - Fluids and Nutrition',
  'MT - Food Hygiene',
  'MT - Handling Medication',
  'MT - Health & Safety',
  'MT - Infection Prevention & Control L2',
  'MT - Information Governance / Caldicott Principles / GDPR',
  'MT - Learning Disability Awareness',
  'MT - Lone Working',
  'MT - Manual Handling Theory',
  'MT - Mental Capacity Act 2005',
];
export const MandatoryTraining = ({
  compliance,
  documents,
  onClickSubmit,
  getDocuments,
  reFetchCompliance,
  onUpdateValidation,
}: MandatoryTrainingProps) => {
  const [isApproved, setIsApproved] = useState<boolean>();
  const [selectedTests, setSelectedTests] = useState<string[]>([]);

  const [filesList, setFilesList] = useState<
    { key: TFilesKey; data: ComplianceFile[] }[]
  >([
    { key: 'trainingCertificateFile', data: [] },
    { key: 'coverageCertificateFile', data: [] },
  ]);

  const onUpdateCompliance = (
    key: TAllFields,
    value: any,
    successMessage?: string,
  ) => {
    onClickSubmit(
      compliance._id,
      {
        [key]: value,
      },
      false,
      successMessage,
    );
  };

  useEffect(() => {
    if (compliance?._id) {
      setSelectedTests(compliance.metadata?.trainingList ?? []);
      setFilesList([
        {
          key: 'trainingCertificateFile',
          data: compliance?.metadata?.trainingCertificateFile ?? [],
        },
        {
          key: 'coverageCertificateFile',
          data: compliance?.metadata?.coverageCertificateFile ?? [],
        },
      ]);
      setIsApproved(compliance.isApproved);
    }
  }, [compliance]);

  return (
    <>
      <>
        <TitleCard data-testid="title-card-application-form-certificate">
          Training Certificate
        </TitleCard>
        <ComplianceUpload
          fileKey="trainingCertificateFile"
          label="Upload File"
          description=""
          getDocuments={getDocuments}
          reFetchCompliance={reFetchCompliance}
          filesList={filesList}
          setFilesList={setFilesList}
          onUpdateCompliance={onUpdateCompliance}
          documents={documents}
        />
      </>
      <>
        <TitleCard data-testid="title-card-application-form-certificate">
          Coverage
        </TitleCard>
        <ComplianceUpload
          fileKey="coverageCertificateFile"
          label="Upload File"
          description=""
          getDocuments={getDocuments}
          reFetchCompliance={reFetchCompliance}
          filesList={filesList}
          setFilesList={setFilesList}
          onUpdateCompliance={onUpdateCompliance}
          documents={documents}
        />
      </>
      <>
        {trainingOptions.map((el) => {
          const isSelected = selectedTests.some((option) => option === el);
          return (
            <div className="medical-option-container">
              <div>{el}</div>
              <div>
                <Switch
                  value={isSelected}
                  onClick={() => {
                    let newList = selectedTests;
                    if (isSelected) {
                      newList = newList.filter((item) => item !== el);
                    } else {
                      newList.push(el);
                    }
                    onUpdateCompliance('trainingList', newList);
                    setSelectedTests(newList);
                  }}
                />
              </div>
            </div>
          );
        })}
      </>

      {/* Approved Section */}
      <>
        <TitleCard data-testid="title-card-approved">Approved</TitleCard>
        <RadioGroup
          options={[
            {
              label: 'Pass',
              value: 'true',
            },
            {
              label: 'Fail',
              value: 'false',
            },
          ]}
          name="approved"
          value={isApproved?.toString()}
          onChange={(value: any) => onUpdateValidation(compliance._id, value)}
          data-testid="approved-radio-group"
        />
        {/* <div
          className="info-card compliance-status buttons-container validated-by-ai"
          data-testid="validate-by-ai-button-container"
        >
          <Button
            type="primary"
            onClick={() => onClickSubmit(compliance._id, {}, true)}
            data-testid="validate-by-ai-button"
          >
            Validate By AI
          </Button>
        </div> */}
      </>
    </>
  );
};
