import { getToken } from './token.utils';
import { EmailAttachment } from '../backend/careo-api';
import { toast } from 'react-toastify';
import { AxiosInstanceErrorResponse } from './axios.utils';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
let csrfToken: string | null = null;

const AxiosInstance = axios.create({
  baseURL: API_URL, // Replace with your API base URL
  withCredentials: true, // Allows cookies and other credentials to be sent
});

const fetchCsrfToken = async () => {
  const response = await AxiosInstance.get(`/auth/csrf-token`, {
    withCredentials: true,
  });
  csrfToken = response.data.csrfToken;
};

await fetchCsrfToken();

export const uploadCandidateFile = async (candidateId: string, file: File) => {
  try {
    const token = getToken();
    const formData = new FormData();
    formData.append('file', file);
    const response = await AxiosInstance.post(
      `/files/upload/candidates/${candidateId}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
          'X-CSRF-TOKEN': csrfToken,
        },
      },
    );

    return response.data;
  } catch (error) {
    toast.error('Something went wrong');
  }
};

export const uploadCandidateFiles = async (
  candidateId: string,
  files: File[],
) => {
  try {
    const token = getToken();
    const formData = new FormData();

    files.forEach((file, index) => {
      formData.append(`files`, file);
    });

    const response = await AxiosInstance.post(
      `/files/upload-multiple/candidates/${candidateId}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
          'X-CSRF-TOKEN': csrfToken,
        },
      },
    );

    return response.data;
  } catch (error) {
    toast.error(
      (error as any).response?.data?.message ?? 'Something went wrong',
    );
  }
};

export const uploadLeadFiles = async (leadId: string, files: File[]) => {
  try {
    const token = getToken();
    const formData = new FormData();

    files.forEach((file, index) => {
      formData.append(`files`, file);
    });

    const response = await AxiosInstance.post(
      `/files/upload-multiple/leads/${leadId}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
          'X-CSRF-TOKEN': csrfToken,
        },
      },
    );

    return response.data;
  } catch (error) {
    toast.error('Something went wrong');
  }
};

export const uploadProjectFiles = async (projectId: string, files: File[]) => {
  try {
    const token = getToken();
    const formData = new FormData();

    files.forEach((file, index) => {
      formData.append(`files`, file);
    });

    const response = await AxiosInstance.post(
      `/files/upload-multiple/projects/${projectId}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
          'X-CSRF-TOKEN': csrfToken,
        },
      },
    );

    return response.data;
  } catch (error) {
    toast.error('Something went wrong');
  }
};

export const uploadPlacementFiles = async (
  placementsId: string,
  files: File[],
) => {
  try {
    const token = getToken();
    const formData = new FormData();

    files.forEach((file, index) => {
      formData.append(`files`, file);
    });

    const response = await AxiosInstance.post(
      `/files/upload-multiple/placements/${placementsId}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
          'X-CSRF-TOKEN': csrfToken,
        },
      },
    );

    return response.data;
  } catch (error) {
    toast.error('Something went wrong');
  }
};

export const sendEmailRequest = async (
  recipient: string,
  subject: string,
  body: string,
  files: File[],
) => {
  const token = getToken();
  const formData = new FormData();
  formData.append('recipient', recipient);
  formData.append('subject', subject);
  formData.append('body', body);
  files.forEach((file) => {
    formData.append('files', file);
  });

  const response = await AxiosInstance.post(`/emails/send-email`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
      'X-CSRF-TOKEN': csrfToken,
    },
  });

  return response?.data;
};

export const draftEmailRequest = async (
  recipient: string,
  subject: string,
  body: string,
  files: File[],
) => {
  const token = getToken();
  const formData = new FormData();
  formData.append('recipient', recipient);
  formData.append('subject', subject);
  formData.append('body', body);
  files.forEach((file) => {
    formData.append('files', file);
  });

  const response = await AxiosInstance.post(`/emails-drafts/create`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
      'X-CSRF-TOKEN': csrfToken,
    },
  });

  return response?.data;
};

export const updateDraftEmailRequest = async (
  id: string,
  recipient: string,
  subject: string,
  body: string,
  files: File[],
  emailAttachments: EmailAttachment[],
) => {
  const token = getToken();
  const formData = new FormData();
  formData.append('recipient', recipient);
  formData.append('subject', subject);
  formData.append('body', body);

  files.forEach((file) => {
    formData.append('files', file);
  });

  const response = await AxiosInstance.patch(`/emails-drafts/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
      'X-CSRF-TOKEN': csrfToken,
    },
  });

  return response.data;
};

export const sendWhatsappRequest = async (
  recipientId: string,
  recipientType: 'client' | 'candidate',
  message: string,
  file?: File,
) => {
  const token = getToken();
  const formData = new FormData();
  formData.append('recipientId', recipientId);
  formData.append('recipientType', recipientType);
  formData.append('message', message);

  if (file) {
    formData.append('file', file);
  }

  const response = await AxiosInstance.post(
    `/whatsapp/${process.env.REACT_APP_NAME}/send-message`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
        'X-CSRF-TOKEN': csrfToken,
      },
    },
  );

  return response.data;
};

export const uploadPayrollsCsvRequest = async (file: File) => {
  try {
    if (!file) {
      return;
    }

    const token = getToken();
    const formData = new FormData();
    formData.append('file', file);

    const response = await AxiosInstance.post(
      `/payrolls/upload-csv`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
          'X-CSRF-TOKEN': csrfToken,
        },
      },
    );

    return response.data;
  } catch (error: any) {
    // Handle Axios error
    const status = error.response?.status || 500;
    let message = error.response?.data?.message || 'Something went wrong';

    if (
      typeof message === 'object' &&
      message !== null &&
      !Array.isArray(message)
    ) {
      message = Object.keys(message)
        .map((key) => `${key.toUpperCase()}: ${message[key]}`)
        .join(' ');
    }

    throw {
      status,
      message,
    } as AxiosInstanceErrorResponse;
  }
};

export const uploadClientsCsvRequest = async (file: File) => {
  try {
    if (!file) {
      return;
    }

    const token = getToken();
    const formData = new FormData();
    formData.append('file', file);

    const response = await AxiosInstance.post(`/clients/upload-csv`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
        'X-CSRF-TOKEN': csrfToken,
      },
    });

    return response.data;
  } catch (error: any) {
    // Handle Axios error
    const status = error.response?.status || 500;
    let message = error.response?.data?.message || 'Something went wrong';

    if (
      typeof message === 'object' &&
      message !== null &&
      !Array.isArray(message)
    ) {
      message = Object.keys(message)
        .map((key) => `${key.toUpperCase()}: ${message[key]}`)
        .join(' ');
    }

    throw {
      status,
      message,
    } as AxiosInstanceErrorResponse;
  }
};

export const uploadTrustsCsvRequest = async (file: File) => {
  try {
    if (!file) {
      return;
    }

    const token = getToken();
    const formData = new FormData();
    formData.append('file', file);

    const response = await AxiosInstance.post(`/trusts/upload-csv`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
        'X-CSRF-TOKEN': csrfToken,
      },
    });

    return response.data;
  } catch (error: any) {
    // Handle Axios error
    const status = error.response?.status || 500;
    let message = error.response?.data?.message || 'Something went wrong';

    if (
      typeof message === 'object' &&
      message !== null &&
      !Array.isArray(message)
    ) {
      message = Object.keys(message)
        .map((key) => `${key.toUpperCase()}: ${message[key]}`)
        .join(' ');
    }

    throw {
      status,
      message,
    } as AxiosInstanceErrorResponse;
  }
};

export const uploadVacanciesCsvRequest = async (file: File) => {
  try {
    if (!file) {
      return;
    }

    const token = getToken();
    const formData = new FormData();
    formData.append('file', file);

    const response = await AxiosInstance.post(`/jobs/upload-csv`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
        'X-CSRF-TOKEN': csrfToken,
      },
    });

    return response.data;
  } catch (error: any) {
    // Handle Axios error
    const status = error.response?.status || 500;
    let message = error.response?.data?.message || 'Something went wrong';

    if (
      typeof message === 'object' &&
      message !== null &&
      !Array.isArray(message)
    ) {
      message = Object.keys(message)
        .map((key) => `${key.toUpperCase()}: ${message[key]}`)
        .join(' ');
    }

    throw {
      status,
      message,
    } as AxiosInstanceErrorResponse;
  }
};

export const uploadCandidatesCsvRequest = async (file: File) => {
  try {
    if (!file) {
      return;
    }

    const token = getToken();
    const formData = new FormData();
    formData.append('file', file);

    const response = await AxiosInstance.post(
      `/candidates/upload-csv`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
          'X-CSRF-TOKEN': csrfToken,
        },
      },
    );

    return response.data;
  } catch (error: any) {
    // Handle Axios error
    const status = error.response?.status || 500;
    let message = error.response?.data?.message || 'Something went wrong';

    if (
      typeof message === 'object' &&
      message !== null &&
      !Array.isArray(message)
    ) {
      message = Object.keys(message)
        .map((key) => `${key.toUpperCase()}: ${message[key]}`)
        .join(' ');
    }

    throw {
      status,
      message,
    } as AxiosInstanceErrorResponse;
  }
};

export const uploadUsersCsvRequest = async (file: File) => {
  try {
    if (!file) {
      return;
    }

    const token = getToken();
    const formData = new FormData();
    formData.append('file', file);

    const response = await AxiosInstance.post(`/users/upload-csv`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
        'X-CSRF-TOKEN': csrfToken,
      },
    });

    return response.data;
  } catch (error: any) {
    // Handle Axios error
    const status = error.response?.status || 500;
    let message = error.response?.data?.message || 'Something went wrong';

    if (
      typeof message === 'object' &&
      message !== null &&
      !Array.isArray(message)
    ) {
      message = Object.keys(message)
        .map((key) => `${key.toUpperCase()}: ${message[key]}`)
        .join(' ');
    }

    throw {
      status,
      message,
    } as AxiosInstanceErrorResponse;
  }
};
