/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export enum ENotificationType {
  CANDIDATE_MATCH = 'CANDIDATE_MATCH',
  COMPLIANCE_REQUIRED = 'COMPLIANCE_REQUIRED',
  COMPLIANCE_WARNING_EXPIRATION = 'COMPLIANCE_WARNING_EXPIRATION',
  COMPLIANCE_EXPIRED = 'COMPLIANCE_EXPIRED',
  COMPLIANCE_NEW_PARSED_DOCUMENT = 'COMPLIANCE_NEW_PARSED_DOCUMENT',
  AI_VACANCY_CREATED = 'AI_VACANCY_CREATED',
  COMPLIANCE_EMPLOYMENT_HISTORY_VALIDATION_IN_PROCESS = 'COMPLIANCE_EMPLOYMENT_HISTORY_VALIDATION_IN_PROCESS',
  COMPLIANCE_EMPLOYMENT_HISTORY_VALIDATION_FAILED = 'COMPLIANCE_EMPLOYMENT_HISTORY_VALIDATION_FAILED',
  COMPLIANCE_EMPLOYMENT_HISTORY_VALIDATION_SUCCESS = 'COMPLIANCE_EMPLOYMENT_HISTORY_VALIDATION_SUCCESS',
  COMPLIANCE_PRO_REG_VALIDATION_IN_PROCESS = 'COMPLIANCE_PRO_REG_VALIDATION_IN_PROCESS',
  COMPLIANCE_PRO_REG_VALIDATION_FAILED = 'COMPLIANCE_PRO_REG_VALIDATION_FAILED',
  COMPLIANCE_PRO_REG_VALIDATION_SUCCESS = 'COMPLIANCE_PRO_REG_VALIDATION_SUCCESS',
  COMPLIANCE_DBS_CHECK_VALIDATION_IN_PROCESS = 'COMPLIANCE_DBS_CHECK_VALIDATION_IN_PROCESS',
  COMPLIANCE_DBS_CHECK_VALIDATION_FAILED = 'COMPLIANCE_DBS_CHECK_VALIDATION_FAILED',
  COMPLIANCE_DBS_CHECK_VALIDATION_SUCCESS = 'COMPLIANCE_DBS_CHECK_VALIDATION_SUCCESS',
  COMPLIANCE_OCCUPATIONAL_HEALTH_CLEARANCE_CHECK_VALIDATION_IN_PROCESS = 'COMPLIANCE_OCCUPATIONAL_HEALTH_CLEARANCE_CHECK_VALIDATION_IN_PROCESS',
  COMPLIANCE_OCCUPATIONAL_HEALTH_CLEARANCE_CHECK_VALIDATION_FAILED = 'COMPLIANCE_OCCUPATIONAL_HEALTH_CLEARANCE_CHECK_VALIDATION_FAILED',
  COMPLIANCE_OCCUPATIONAL_HEALTH_CLEARANCE_CHECK_VALIDATION_SUCCESS = 'COMPLIANCE_OCCUPATIONAL_HEALTH_CLEARANCE_CHECK_VALIDATION_SUCCESS',
  CANDIDATE_REJECT_PLACEMENT = 'CANDIDATE_REJECT_PLACEMENT',
  CANDIDATE_CONFIRMED_PLACEMENT = 'CANDIDATE_CONFIRMED_PLACEMENT',
  CLIENT_REJECT_PLACEMENT = 'CLIENT_REJECT_PLACEMENT',
  CLIENT_CONFIRMED_PLACEMENT = 'CLIENT_CONFIRMED_PLACEMENT',
}

export enum ENotificationStatus {
  Info = 'info',
  Error = 'error',
  Warning = 'warning',
  Success = 'success',
}

export interface ProfessionalRegistration {
  authority: string;
  number: string;
}

export enum ESpecialty {
  GeneralMedicine = 'General Medicine',
  Cardiology = 'Cardiology',
  EndocrinologyDiabetes = 'Endocrinology & Diabetes',
  Gastroenterology = 'Gastroenterology',
  Geriatrics = 'Geriatrics',
  Haematology = 'Haematology',
  InfectiousDiseases = 'Infectious Diseases',
  Nephrology = 'Nephrology',
  Neurology = 'Neurology',
  RespiratoryMedicine = 'Respiratory Medicine',
  Rheumatology = 'Rheumatology',
  Dermatology = 'Dermatology',
  ClinicalPharmacology = 'Clinical Pharmacology',
  Immunology = 'Immunology',
  Oncology = 'Oncology',
  MedicalOncology = 'Medical Oncology',
  ClinicalOncology = 'Clinical Oncology',
  PalliativeMedicine = 'Palliative Medicine',
  GenitourinaryMedicineGUMED = 'Genitourinary Medicine (GU MED)',
  GeneralSurgery = 'General Surgery',
  VascularSurgery = 'Vascular Surgery',
  CardiothoracicSurgery = 'Cardiothoracic Surgery',
  Neurosurgery = 'Neurosurgery',
  OralMaxillofacialSurgery = 'Oral & Maxillofacial Surgery',
  OtolaryngologyENT = 'Otolaryngology (ENT)',
  TraumaOrthopaedicSurgery = 'Trauma & Orthopaedic Surgery',
  PaediatricSurgery = 'Paediatric Surgery',
  PlasticSurgery = 'Plastic Surgery',
  Urology = 'Urology',
  GeneralPaediatrics = 'General Paediatrics',
  Neonatology = 'Neonatology',
  PaediatricCardiology = 'Paediatric Cardiology',
  PaediatricNeurology = 'Paediatric Neurology',
  EmergencyAndAcuteCare = 'Emergency and Acute Care',
  EmergencyMedicine = 'Emergency Medicine',
  AcuteInternalMedicine = 'Acute Internal Medicine',
  GeneralAdultMentalHealth = 'General Adult Mental Health',
  OldAgeMentalHealth = 'Old Age Mental Health',
  ChildAndAdolescentMentalHealth = 'Child and Adolescent Mental Health',
  ForensicMentalHealth = 'Forensic Mental Health',
  LiaisonMentalHealth = 'Liaison Mental Health',
  LearningDisabilityMentalHealth = 'Learning Disability Mental Health',
  Anaesthetics = 'Anaesthetics',
  PainMedicine = 'Pain Medicine',
  IntensiveCareMedicine = 'Intensive Care Medicine',
  ObstetricsGynaecology = 'Obstetrics & Gynaecology',
  Obstetrics = 'Obstetrics',
  Gynaecology = 'Gynaecology',
  MaternalFetalMedicine = 'Maternal-Fetal Medicine',
  DiagnosticRadiology = 'Diagnostic Radiology',
  InterventionalRadiology = 'Interventional Radiology',
  ChemicalPathology = 'Chemical Pathology',
  Histopathology = 'Histopathology',
  ForensicPathology = 'Forensic Pathology',
  Microbiology = 'Microbiology',
  Virology = 'Virology',
  Ophthalmology = 'Ophthalmology',
  PublicHealthMedicine = 'Public Health Medicine',
  OccupationalMedicine = 'Occupational Medicine',
  SportsAndExerciseMedicine = 'Sports and Exercise Medicine',
  NuclearMedicine = 'Nuclear Medicine',
  ClinicalGenetics = 'Clinical Genetics',
  RehabilitationMedicine = 'Rehabilitation Medicine',
  ClinicalNeurophysiology = 'Clinical Neurophysiology',
  TropicalMedicine = 'Tropical Medicine',
  AddictionMedicine = 'Addiction Medicine',
  SleepMedicine = 'Sleep Medicine',
  AllergyAndImmunology = 'Allergy and Immunology',
  VascularMedicine = 'Vascular Medicine',
  PediatricInfectiousDiseases = 'Pediatric Infectious Diseases',
  AudioVestibularMedicine = 'Audio Vestibular Medicine',
  AviationAndSpaceMedicine = 'Aviation and Space Medicine',
  ChildAndAdolescentPsychiatry = 'Child and Adolescent Psychiatry',
  ClinicalRadiology = 'Clinical Radiology',
  CommunitySexualAndReproductiveHealth = 'Community Sexual and Reproductive Health',
  DiagnosticNeuropathology = 'Diagnostic Neuropathology',
  EndocrinologyAndDiabetesMellitus = 'Endocrinology and Diabetes Mellitus',
  ForensicHistopathology = 'Forensic Histopathology',
  ForensicPsychiatry = 'Forensic Psychiatry',
  GeneralInternalMedicine = 'General Internal Medicine',
  GeneralPractice = 'General Practice',
  GeneralPsychiatry = 'General Psychiatry',
  GeriatricMedicine = 'Geriatric Medicine',
  MedicalMicrobiology = 'Medical Microbiology',
  MedicalOphthalmology = 'Medical Ophthalmology',
  MedicalPsychotherapy = 'Medical Psychotherapy',
  MedicalVirology = 'Medical Virology',
  OldAgePsychiatry = 'Old Age Psychiatry',
  Paediatrics = 'Paediatrics',
  PharmaceuticalMedicine = 'Pharmaceutical Medicine',
  PsychiatryOfLearningDisability = 'Psychiatry of Learning Disability',
  RenalMedicine = 'Renal Medicine',
  TraumaAndOrthopaedicSurgery = 'Trauma and Orthopaedic Surgery',
  AdvancedNursePractitioner = 'Advanced Nurse Practitioner',
  AdmissionsDischarge = 'Admissions/Discharge',
  AdultCommunityServices = 'Adult Community Services',
  AutismAssessor = 'Autism Assessor',
  BookingScheduling = 'Booking/Scheduling',
  CommunityServices = 'Community Services',
  Dentistry = 'Dentistry',
  Dental = 'Dental',
  ENT = 'ENT',
  ODP = 'ODP',
  OMFS = 'OMFS',
  Orthodontics = 'Orthodontics',
  Psychologist = 'Psychologist',
  Radiographer = 'Radiographer',
  Radiologist = 'Radiologist',
  Radiology = 'Radiology',
  RadiologyAssistant = 'Radiology Assistant',
  Receptionist = 'Receptionist',
  Recovery = 'Recovery',
  Respiratory = 'Respiratory',
  Scrub = 'Scrub',
  Sonographer = 'Sonographer',
  SpeechLanguageSpecialist = 'Speech & Language Specialist',
  TO = 'T&O',
  BreastSurgical = 'Breast Surgical',
  Chaperone = 'Chaperone',
  ChildrensCommunityServices = "Children's Community Services",
  ClinicalLead = 'Clinical Lead',
  CommunityPaediatrician = 'Community Paediatrician',
}

export enum ENhsPayGrade {
  Band5 = 'Band 5',
  Band6 = 'Band 6',
  Band7 = 'Band 7',
  Band8A = 'Band 8a',
  Band8B = 'Band 8b',
  Band8C = 'Band 8c',
  Band8D = 'Band 8d',
  Band9 = 'Band 9',
  FY1 = 'FY1',
  FY2 = 'FY2',
  ST1 = 'ST1',
  ST2 = 'ST2',
  ST3 = 'ST3',
  ST4 = 'ST4',
  ST5 = 'ST5',
  ST6 = 'ST6',
  ST7 = 'ST7',
  ST8 = 'ST8',
  AssociateSpecialist = 'Associate Specialist',
  StaffGrade = 'Staff Grade',
  SeniorHouseOfficer = 'Senior House Officer',
  SpecialtyDoctor = 'Specialty Doctor',
  Consultant = 'Consultant',
}

export enum EJobLevel {
  Nurse = 'Nurse',
  JuniorDoctor = 'Junior Doctor',
  MiddleGrade = 'Middle Grade',
  Consultant = 'Consultant',
  HCA = 'HCA',
  NonClinical = 'Non Clinical',
}

export enum EUserRole {
  Admin = 'admin',
  Recruiter = 'recruiter',
  Officer = 'officer',
  Manager = 'manager',
}

export interface RefreshToken {
  token: string;
  deviceId: string;
  /** @format date-time */
  expiresAt: string;
}

export interface User {
  createdAt?: string;
  deletedAt?: string;
  deletedBy?: string;
  createdBy?: string;
  actionByUserId?: string;
  _id: string;
  email: string;
  password: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  role: EUserRole;
  emailLinked: boolean;
  refreshTokens: RefreshToken[];
}

export interface Note {
  _id: string;
  text: string;
  /** @format date-time */
  createdAt: string;
  user: User;
}

export interface Address {
  street?: string;
  city?: string;
  county?: string;
  region?: string;
  country?: string;
  zip?: string;
}

export interface Bank {
  name: string;
  accountName: string;
  accountNumber: string;
  sortNumber: string;
}

export interface Payroll {
  createdAt?: string;
  deletedAt?: string;
  deletedBy?: string;
  createdBy?: string;
  actionByUserId?: string;
  _id: string;
  payrollProviderName: string;
  vatNumber?: string;
  payrollLogo?: object;
  title: string;
  leadContactFirstName: string;
  leadContactLastName: string;
  leadContactEmail: string;
  leadContactJobTitle?: string;
  leadContactMobilePhoneNumber: string;
  leadContactWorkPhoneNumber?: string;
  leadContactFax?: string;
  website?: string;
  account?: string;
  employmentType?: string;
  standardWorkingHoursPerDay?: string;
  document?: object;
  address?: Address;
  bank?: Bank;
  user: User;
  isProfileComplete: boolean;
}

export interface Candidate {
  createdAt?: string;
  deletedAt?: string;
  deletedBy?: string;
  createdBy?: string;
  actionByUserId?: string;
  _id: string;
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  homePhoneNumber?: string;
  workPhoneNumber?: string;
  /** @format date-time */
  birthDate?: string;
  nationality: string;
  gender?: string;
  niNumber?: string;
  professionalRegistrations?: ProfessionalRegistration[];
  dbsCertificateNumber?: string;
  hiredStatus?: string;
  departments?: ESpecialty[];
  grade?: ENhsPayGrade;
  designation?: EJobLevel;
  payAmount?: number;
  currentlyEmployed?: string;
  whatsappId: string;
  drivingLicence?: boolean;
  ownCar?: boolean;
  aiMatched?: boolean;
  notes: Note[];
  recruiter?: User;
  officer?: User;
  payrollProvider?: Payroll;
  address?: Address;
  isProfileComplete: boolean;
}

export interface Notification {
  createdAt?: string;
  deletedAt?: string;
  deletedBy?: string;
  createdBy?: string;
  actionByUserId?: string;
  _id: string;
  type: ENotificationType;
  status?: ENotificationStatus;
  data: object;
  /** @format date-time */
  createdDate: string;
  /** @format date-time */
  viewedDate?: string;
  /** @format date-time */
  deletedDate?: string;
  candidate: Candidate;
}

export interface HideNotificationDto {
  ids: string[];
}

export interface CreateAddressDto {
  street?: string;
  city?: string;
  county?: string;
  region?: string;
  country?: string;
  zip?: string;
}

export interface CreateCandidateDto {
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  homePhoneNumber?: string;
  workPhoneNumber?: string;
  /** @format date-time */
  birthDate?: string;
  nationality?: string;
  gender?: string;
  niNumber?: string;
  hiredStatus?: string;
  departments?: ESpecialty[];
  grade?: ENhsPayGrade;
  professionalRegistrations?: ProfessionalRegistration[];
  designation?: EJobLevel;
  payAmount?: number;
  currentlyEmployed?: string;
  drivingLicence?: boolean;
  ownCar?: boolean;
  payrollProviderId?: string;
  recruiterId?: string;
  officerId?: string;
  address: CreateAddressDto;
}

export interface UpdateCandidateDto {
  title?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  homePhoneNumber?: string;
  workPhoneNumber?: string;
  /** @format date-time */
  birthDate?: string;
  nationality?: string;
  gender?: string;
  niNumber?: string;
  hiredStatus?: string;
  departments?: ESpecialty[];
  grade?: ENhsPayGrade;
  professionalRegistrations?: ProfessionalRegistration[];
  designation?: EJobLevel;
  payAmount?: number;
  currentlyEmployed?: string;
  drivingLicence?: boolean;
  ownCar?: boolean;
  payrollProviderId?: string;
  recruiterId?: string;
  officerId?: string;
  address?: CreateAddressDto;
  professionalRegistrationNumber?: string;
  professionalRegistrationAuthority?: string;
  dbsCertificateNumber?: string;
}

export enum EComplianceStatus {
  NOT_ACTIVE = 'NOT_ACTIVE',
  IN_PROGRESS = 'IN_PROGRESS',
  PASSED = 'PASSED',
  FAILED = 'FAILED',
  EXPIRED = 'EXPIRED',
}

export interface ExtendedCandidate {
  createdAt?: string;
  deletedAt?: string;
  deletedBy?: string;
  createdBy?: string;
  actionByUserId?: string;
  _id: string;
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  homePhoneNumber?: string;
  workPhoneNumber?: string;
  /** @format date-time */
  birthDate?: string;
  nationality: string;
  gender?: string;
  niNumber?: string;
  professionalRegistrations?: ProfessionalRegistration[];
  dbsCertificateNumber?: string;
  hiredStatus?: string;
  departments?: ESpecialty[];
  grade?: ENhsPayGrade;
  designation?: EJobLevel;
  payAmount?: number;
  currentlyEmployed?: string;
  whatsappId: string;
  drivingLicence?: boolean;
  ownCar?: boolean;
  aiMatched?: boolean;
  notes: Note[];
  recruiter?: User;
  officer?: User;
  payrollProvider?: Payroll;
  address?: Address;
  isProfileComplete: boolean;
  complianceStatus?: EComplianceStatus;
  completionPercentage?: number;
}

export interface CreateNoteCandidateDto {
  text: string;
}

export interface CreateCandidatesListDto {
  name: string;
  candidates: string[];
  sharedUsers: string[];
}

export interface CandidatesList {
  _id: string;
  name: string;
  user: User;
  sharedUsers: User[];
  candidates: Candidate[];
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
}

export interface UpdateCandidatesListDto {
  name?: string;
  candidates?: string[];
  sharedUsers?: string[];
}

export enum EComplianceName {
  RegistrationDocuments = 'Registration Documents',
  EmploymentHistoryReferences = 'Employment History & References',
  RightToWork = 'Right to work',
  CriminalRecordCheck = 'Criminal Record Check',
  IDBadge = 'ID Badge',
  ProfessionalRegistration = 'Professional Registration',
  OccupationalHealthClearance = 'Occupational Health Clearance',
  MandatoryTraining = 'Mandatory Training',
}

export interface Trust {
  createdAt?: string;
  deletedAt?: string;
  deletedBy?: string;
  createdBy?: string;
  actionByUserId?: string;
  _id: string;
  name: string;
  description?: string;
  user: User;
}

export interface Department {
  _id: string;
  titleContact: string;
  firstNameContact: string;
  lastNameContact: string;
  departmentContact: string;
  teamContact: string;
  jobTitleContact: string;
  emailContact: string;
  phoneNumberContact: string;
  workPhoneNumberContact: string;
  document?: string;
  titleAccount: string;
  firstNameAccount: string;
  lastNameAccount: string;
  jobTitleAccount: string;
  emailAccount: string;
  phoneNumberAccount: string;
  workPhoneNumberAccount: string;
  bank: Bank;
  specialty: ESpecialty;
}

export enum EChargeRateType {
  Doctors = 'Doctors',
  NursesHealthCareAssistants = 'Nurses & Health Care Assistants',
}

export interface DayWorkingHours {
  from?: string;
  to?: string;
  isWorkingDay: boolean;
}

export interface WeekWorkingHoursDto {
  monday: DayWorkingHours;
  tuesday: DayWorkingHours;
  wednesday: DayWorkingHours;
  thursday: DayWorkingHours;
  friday: DayWorkingHours;
  saturday: DayWorkingHours;
  sunday: DayWorkingHours;
}

export interface PricingPerGradeDto {
  grade: ENhsPayGrade;
  corePrice: number;
  unSocialPrice: number;
  onCallActivePrice: number;
  onCallPassivePrice: number;
  charge: number;
}

export interface ChargesRates {
  _id: string;
  type: EChargeRateType;
  specialty?: ESpecialty;
  coreHours: WeekWorkingHoursDto;
  pricing: PricingPerGradeDto[];
}

export interface Client {
  createdAt?: string;
  deletedAt?: string;
  deletedBy?: string;
  createdBy?: string;
  actionByUserId?: string;
  _id: string;
  clientName: string;
  trust?: Trust;
  title: string;
  firstName: string;
  lastName: string;
  jobTitle?: string;
  email: string;
  phoneNumber: string;
  workPhoneNumber?: string;
  faxNumber?: string;
  website?: string;
  document?: string;
  address?: Address;
  user: User;
  bank?: Bank;
  departments: Department[];
  whatsappId: string;
  isProfileComplete: boolean;
  chargesRates: ChargesRates[];
}

export enum EApplicationStatus {
  Referred = 'referred',
  AiMatched = 'ai_matched',
  Rejected = 'rejected',
  Shortlist = 'shortlist',
  Placement = 'placement',
}

export enum EJobShift {
  Day = 'day',
  Night = 'night',
}

export enum EJobStatus {
  Hired = 'hired',
  Pending = 'pending',
  Active = 'active',
  Closed = 'closed',
}

/** @default "time" */
export enum EPayOption {
  Time = 'time',
  Fixed = 'fixed',
}

export interface Job {
  createdAt?: string;
  deletedAt?: string;
  deletedBy?: string;
  createdBy?: string;
  actionByUserId?: string;
  _id: string;
  grade?: ENhsPayGrade;
  level?: EJobLevel;
  shift: EJobShift;
  specialty: ESpecialty;
  jobDescription: string;
  status?: EJobStatus;
  /** @format date-time */
  vacantFrom: string;
  /** @format date-time */
  vacantTo: string;
  coreHours: WeekWorkingHoursDto;
  user?: User;
  client: Client;
  payOption: EPayOption;
  corePrice: number;
  charge: number;
  hoursPerWeek: number;
  vat?: number;
  unSocialPrice: number;
  onCallActivePrice: number;
  onCallPassivePrice: number;
}

export enum EApplicationApprovalStatus {
  NOT_ACTIVE = 'NOT_ACTIVE',
  PENDING = 'PENDING',
  CANDIDATE_CONFIRMED = 'CANDIDATE_CONFIRMED',
  CANDIDATE_REJECTED = 'CANDIDATE_REJECTED',
  CLIENT_CONFIRMED = 'CLIENT_CONFIRMED',
  CLIENT_REJECTED = 'CLIENT_REJECTED',
  RECRUITER_APPROVED = 'RECRUITER_APPROVED',
  RECRUITER_DECLINED = 'RECRUITER_DECLINED',
  RECRUITER_CANCELED = 'RECRUITER_CANCELED',
  RECRUITER_STOPPED = 'RECRUITER_STOPPED',
  SYSTEM_CANCELLED = 'SYSTEM_CANCELLED',
}

export interface Application {
  createdAt?: string;
  deletedAt?: string;
  deletedBy?: string;
  createdBy?: string;
  actionByUserId?: string;
  _id: string;
  status: EApplicationStatus;
  availableFrom: string;
  availableTo: string;
  job: Job;
  candidate: Candidate;
  fee?: number;
  coreRate?: number;
  unSocialRate?: number;
  onCallFee?: number;
  onCallActiveRate?: number;
  onCallPassiveRate?: number;
  vat?: number;
  approvalStatus?: EApplicationApprovalStatus;
  candidateToken?: string;
  clientToken?: string;
  /** @format date-time */
  createdDate: string;
}

export interface Timing {
  /** @format date-time */
  date: string;
  startTime: string;
  endTime: string;
  callActiveHours: number;
  callPassiveHours: number;
  workingCoreHours: number;
  unsocialHours: number;
  breakTime: number;
}

export enum ETimesheetStatus {
  Generated = 'Generated',
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
}

export interface Timesheet {
  createdAt?: string;
  deletedAt?: string;
  deletedBy?: string;
  createdBy?: string;
  actionByUserId?: string;
  _id: string;
  placement: Application;
  timings: Timing[];
  signature?: string;
  status: ETimesheetStatus;
  /** @format date-time */
  generatedDate: string;
  clientToken?: string;
  candidateToken?: string;
}

export enum ELeadStatus {
  NewLead = 'New Lead',
  ScopingPhase = 'Scoping Phase',
  ProposalSent = 'Proposal Sent',
  ContractSigned = 'Contract Signed',
  EarlyPlanning = 'Early Planning',
  AdvancedPlanning = 'Advanced Planning',
  OperationalGoLive = 'Operational Go Live',
}

export enum ELeadStrength {
  Cold = 'Cold',
  Warm = 'Warm',
  Hot = 'Hot',
}

export enum ELeadTag {
  Live = 'Live',
  NonProgressive = 'Non-Progressive',
  Converted = 'Converted',
}

export enum ELeadType {
  New = 'New',
  Extension = 'Extension',
}

export enum EMonth {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
  Value9 = 9,
  Value10 = 10,
  Value11 = 11,
}

export enum ELeadDurationType {
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
}

export interface Lead {
  createdAt?: string;
  deletedAt?: string;
  deletedBy?: string;
  createdBy?: string;
  actionByUserId?: string;
  _id: string;
  name: string;
  status: ELeadStatus;
  strength: ELeadStrength;
  tag: ELeadTag;
  type: ELeadType;
  cpms: User[];
  region: string;
  trust?: Trust;
  specialty: ESpecialty;
  hospital: Client;
  overview?: string;
  resourceRequirement: string;
  startingMonth: EMonth;
  durationValue: number;
  durationType: ELeadDurationType;
  revenue?: number;
  cost?: number;
  margin?: number;
  benchmark?: number;
  legalContact?: User;
}

export enum ESubSpecialty {
  AddictionPsychiatry = 'Addiction Psychiatry',
  ChildMentalHealth = 'Child Mental Health',
  CommunityChildHealth = 'Community Child Health',
  CongenitalCardiacSurgery = 'Congenital Cardiac Surgery',
  Cytopathology = 'Cytopathology',
  GynaecologicalOncology = 'Gynaecological Oncology',
  Hepatology = 'Hepatology',
  InterventionalRadiology = 'Interventional Radiology',
  LiaisonPsychiatry = 'Liaison Psychiatry',
  MaternalAndFetalMedicine = 'Maternal and Fetal Medicine',
  MetabolicMedicine = 'Metabolic Medicine',
  NeonatalMedicine = 'Neonatal Medicine',
  PaediatricAllergyImmunologyAndInfectiousDiseases = 'Paediatric Allergy, Immunology, and Infectious Diseases',
  PaediatricClinicalPharmacologyAndTherapeutics = 'Paediatric Clinical Pharmacology and Therapeutics',
  PaediatricDiabetesAndEndocrinology = 'Paediatric Diabetes and Endocrinology',
  PaediatricEmergencyMedicine = 'Paediatric Emergency Medicine',
  PaediatricGastroenterologyHepatologyAndNutrition = 'Paediatric Gastroenterology, Hepatology, and Nutrition',
  PaediatricInheritedMetabolicMedicine = 'Paediatric Inherited Metabolic Medicine',
  PaediatricIntensiveCareMedicine = 'Paediatric Intensive Care Medicine',
  PaediatricNephrology = 'Paediatric Nephrology',
  PaediatricNeurodisability = 'Paediatric Neurodisability',
  PaediatricNeurology = 'Paediatric Neurology',
  PaediatricOncology = 'Paediatric Oncology',
  PaediatricPalliativeMedicine = 'Paediatric Palliative Medicine',
  PaediatricRespiratoryMedicine = 'Paediatric Respiratory Medicine',
  PaediatricRheumatology = 'Paediatric Rheumatology',
  PreHospitalEmergencyMedicine = 'Pre-Hospital Emergency Medicine',
  RehabilitationPsychiatry = 'Rehabilitation Psychiatry',
  ReproductiveMedicine = 'Reproductive Medicine',
  StrokeMedicine = 'Stroke Medicine',
  Urogynaecology = 'Urogynaecology',
}

export enum EProjectActivityType {
  Clinic = 'clinic',
  Theatre = 'theatre',
  RemoteClinic = 'remote clinic',
}

export enum EProjectActivityRateType {
  PerPatient = 'Per Patient',
}

export interface Role {
  level: EJobLevel;
  grade: ENhsPayGrade;
  startTime: string;
  endTime: string;
  rate: number;
  expanse: number;
}

export interface Activity {
  subSpecialty: ESubSpecialty;
  activityType: EProjectActivityType;
  startDate: string;
  endDate: string;
  clientDepartment?: Department;
  rateType: EProjectActivityRateType;
  rate: number;
  template: number;
  bcv: number;
  roles: Role[];
}

export enum EProjectRevenueType {
  Session = 'Session',
}

export enum EProjectPaymentFrequency {
  Hourly = 'hourly',
  Weekly = 'weekly',
  Monthly = 'monthly',
}

export enum EProjectPaymentTerms {
  Value30Days = '30 Days',
}

export interface Project {
  createdAt?: string;
  deletedAt?: string;
  deletedBy?: string;
  createdBy?: string;
  actionByUserId?: string;
  _id: string;
  name: string;
  specialty: ESpecialty;
  cpms: User[];
  serviceCoordinator: User;
  cem: User;
  trust?: Trust;
  client: Client;
  region: string;
  report: string;
  activities: Activity[];
  revenuePer?: EProjectRevenueType;
  revenue?: number;
  cost?: number;
  margin?: number;
  purchaseOrderNumber?: number;
  contact?: User;
  invoicingTo?: string;
  invoicingCC?: string;
  staffPayFrequency?: EProjectPaymentFrequency;
  paymentTerms?: EProjectPaymentTerms;
  invoiceFrequency?: EProjectPaymentFrequency;
  legalContact?: User;
}

export interface Invoice {
  createdAt?: string;
  deletedAt?: string;
  deletedBy?: string;
  createdBy?: string;
  actionByUserId?: string;
  _id: string;
  placement: Application;
  timesheet: Timesheet;
  /** @format date-time */
  createdDate: string;
  referenceNumber: number;
}

export interface HostedFile {
  createdAt?: string;
  deletedAt?: string;
  deletedBy?: string;
  createdBy?: string;
  actionByUserId?: string;
  _id: string;
  fileName: string;
  fileKey: string;
  candidate?: Candidate;
  client?: Client;
  placement?: Application;
  timesheet?: Timesheet;
  lead?: Lead;
  project?: Project;
  invoice?: Invoice;
  isPublic: boolean;
  /** @format date-time */
  timestamp: string;
  emailMessageId: string;
  /** @format date-time */
  archiveDate?: string;
}

export interface ComplianceFile {
  file: HostedFile;
  /** @format date-time */
  addedDate: string;
  /** @format date-time */
  archiveDate?: string;
}

export enum EVisaTimePeriod {
  Value1Year = '1 year',
  IndefiniteLeaveToRemain = 'Indefinite Leave to Remain',
}

export enum ECriminalRecordCheckType {
  Dbs = 'dbs',
  Pvg = 'pvg',
  Ni = 'ni',
}

export interface ComplianceMetaData {
  createdAt?: string;
  deletedAt?: string;
  deletedBy?: string;
  createdBy?: string;
  actionByUserId?: string;
  _id?: string;
  applicationFormFile?: ComplianceFile[];
  handbookFile?: ComplianceFile[];
  isHandbookSent?: boolean;
  cvFile?: ComplianceFile[];
  isCvValid?: boolean;
  references?: ComplianceFile[];
  isReferencesValid?: boolean;
  passport?: string;
  passportFiles?: ComplianceFile[];
  certificate?: string;
  certificateFiles?: ComplianceFile[];
  hasWorkRight?: boolean;
  visaFiles?: ComplianceFile[];
  visaTimePeriod?: EVisaTimePeriod;
  visaExpiryDate?: string;
  visaShareCode?: string;
  dbsCertificateFile?: ComplianceFile[];
  dbsCheckFile?: ComplianceFile[];
  pvgCertificateFile?: ComplianceFile[];
  pvgCheckFile?: ComplianceFile[];
  niCertificateFile?: ComplianceFile[];
  niCheckFile?: ComplianceFile[];
  typeCheck?: string;
  criminalRecordType?: ECriminalRecordCheckType;
  onDbsUpdateService?: boolean;
  isPerformCheckActivated?: boolean;
  dbsTimePeriod?: string;
  dbsCustomDate?: string;
  profilePictureFile?: ComplianceFile[];
  badgeFile?: ComplianceFile[];
  isBadgeSent?: boolean;
  idTimePeriod?: string;
  idCustomDate?: string;
  registrationCertificates?: ComplianceFile[];
  registrationChecksFiles?: ComplianceFile[];
  fitnessToWorkCertificateFile?: ComplianceFile[];
  bloodsFile?: ComplianceFile[];
  medicalQuestionnaireFile?: ComplianceFile[];
  medicalTestsList?: string[];
  trainingCertificateFile?: ComplianceFile[];
  coverageCertificateFile?: ComplianceFile[];
  trainingList?: string[];
  note?: string;
  ids?: ComplianceFile[];
  driverLicense?: ComplianceFile[];
  proofAddress?: ComplianceFile[];
  graduateTrainingFiles?: ComplianceFile[];
  questionnaireFiles?: ComplianceFile[];
  questionnaireFiles2?: ComplianceFile[];
  isAllReport?: boolean;
  isOccupationHealth?: boolean;
  isReporting?: boolean;
}

export interface CreateComplianceDto {
  taskName: EComplianceName;
  /** @format date-time */
  reminderDate?: string;
  /** @format date-time */
  expiryDate?: string;
  isApproved?: boolean;
  isActive?: boolean;
  metadata?: ComplianceMetaData;
}

export interface CreateComplianceListDto {
  compliances: CreateComplianceDto[];
}

export interface Compliance {
  createdAt?: string;
  deletedAt?: string;
  deletedBy?: string;
  createdBy?: string;
  actionByUserId?: string;
  _id: string;
  taskName: EComplianceName;
  /** @format date-time */
  reminderDate?: string;
  /** @format date-time */
  expiryDate?: string;
  isApproved: boolean;
  status: EComplianceStatus;
  metadata?: ComplianceMetaData;
  candidate: Candidate;
  isActive: boolean;
}

export interface UpdateComplianceDto {
  /** @format date-time */
  reminderDate?: string;
  /** @format date-time */
  expiryDate?: string;
  isApproved?: boolean;
  metadata?: ComplianceMetaData;
}

export interface TaskAnalytics {
  /**
   * The count of tasks
   * @example 10
   */
  count: number;
  /**
   * The percentage of tasks
   * @example 50
   */
  percentage: number;
}

export interface UserAnalytics {
  /** In-progress tasks analytics */
  inProgressTasks: TaskAnalytics;
  /** Passed tasks analytics */
  passedTasks: TaskAnalytics;
  /** Failed tasks analytics */
  failedTasks: TaskAnalytics;
  /** Expired tasks analytics */
  expiredTasks: TaskAnalytics;
}

export interface DurationDto {
  availableFrom: string;
  availableTo: string;
}

export interface CreateApplicationDto {
  status?: EApplicationStatus;
  durations: DurationDto[];
  jobId: string;
  candidateId: string;
}

export interface UpdateApplicationStatusDto {
  status: EApplicationStatus;
}

export interface ValidatePlacementDto {
  fee: number;
  vat: number;
  onCallFee: number;
  onCallActiveRate: number;
  onCallPassiveRate: number;
  unSocialRate: number;
  coreRate: number;
}

export enum EAiJobShift {
  Day = 'day',
  Night = 'night',
  FullDay = 'fullDay',
}

export interface AddAvailabilityDto {
  /** @format date-time */
  from: string;
  /** @format date-time */
  to?: string;
  availabilityTime: EAiJobShift;
  candidateId: string;
}

export enum EAvailabilityStatus {
  AVAILABLE = 'AVAILABLE',
  PLACED = 'PLACED',
}

export interface Availability {
  createdAt?: string;
  deletedAt?: string;
  deletedBy?: string;
  createdBy?: string;
  actionByUserId?: string;
  _id: string;
  /** @format date-time */
  date: string;
  availabilityTime: EJobShift;
  status: EAvailabilityStatus;
  candidate: Candidate;
}

export interface DeleteAvailabilityDto {
  /** @format date-time */
  from: string;
  /** @format date-time */
  to?: string;
  candidateId: string;
  shift: EJobShift;
}

export interface CreateJobDto {
  specialty: ESpecialty;
  grade: ENhsPayGrade;
  level: EJobLevel;
  shift: EJobShift;
  jobDescription: string;
  status: EJobStatus;
  /** @format date-time */
  vacantFrom: string;
  /** @format date-time */
  vacantTo: string;
  coreHours: WeekWorkingHoursDto;
  userId?: string;
  clientId: string;
  payOption: EPayOption;
  corePrice: number;
  /** @default 0 */
  unSocialPrice: number;
  /** @default 0 */
  onCallActivePrice: number;
  /** @default 0 */
  onCallPassivePrice: number;
  charge: number;
  hoursPerWeek?: number;
  vat: number;
}

export interface UpdateJobDto {
  specialty?: ESpecialty;
  grade?: ENhsPayGrade;
  level?: EJobLevel;
  shift?: EJobShift;
  jobDescription?: string;
  status?: EJobStatus;
  /** @format date-time */
  vacantFrom?: string;
  /** @format date-time */
  vacantTo?: string;
  coreHours?: WeekWorkingHoursDto;
  userId?: string;
  clientId?: string;
  payOption?: EPayOption;
  corePrice?: number;
  /** @default 0 */
  unSocialPrice?: number;
  /** @default 0 */
  onCallActivePrice?: number;
  /** @default 0 */
  onCallPassivePrice?: number;
  charge?: number;
  hoursPerWeek?: number;
  vat?: number;
}

export interface CreateUserDto {
  phoneNumber: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  role: EUserRole;
}

export interface UpdateUserDto {
  phoneNumber?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  password?: string;
  confirmPassword?: string;
  role?: EUserRole;
}

export interface SignInDto {
  email: string;
  password: string;
}

export interface SignInResponse {
  accessToken: string;
  otpRequired: boolean;
}

export interface VerifyOtpDto {
  email: string;
  otp: string;
}

export interface RefreshTokenResponse {
  accessToken: string;
}

export interface ForgetPasswordDto {
  email: string;
}

export interface ResetPasswordDto {
  password: string;
}

export enum EMAIL_PROVIDERS {
  GMAIL = 'GMAIL',
  OUTLOOK = 'OUTLOOK',
}

export interface AuthUrlResponseDto {
  url: string;
}

export interface LabelsDetailsResponseDto {
  label: string;
  messagesTotal: number;
  messagesUnread: number;
}

export interface EmailBody {
  messageId: string;
  from: string;
  date: string;
  subject: string;
  body: string;
  bodyHtml: string;
  isRead: boolean;
  to: string[];
  labels: ('INBOX' | 'SENT' | 'DRAFT' | 'TRASH' | 'SPAM')[];
  attachments: (EmailAttachment | HostedFile)[];
  draftId?: string;
}

export enum BOX_TYPE {
  INBOX = 'INBOX',
  SENT = 'SENT',
  DRAFT = 'DRAFT',
  TRASH = 'TRASH',
  SPAM = 'SPAM',
}

export interface UserEmailsResponseDto {
  emails: EmailBody[];
  nextPageToken: string;
  previousPageToken: string;
}

export interface SendEmailDto {
  recipient: string;
  subject: string;
  body: string;
  /** File to upload */
  files?: File[];
}

export interface CreateDraftDto {
  recipient: string;
  subject: string;
  body: string;
  /** File to upload */
  files?: File[];
}

export interface UpdateDraftDto {
  recipient: string;
  subject: string;
  body: string;
  /** File to upload */
  files?: File[];
}

export interface CreateBankDto {
  name?: string;
  accountName?: string;
  accountNumber?: string;
  sortNumber?: string;
}

export interface CreatePayrollDto {
  payrollProviderName: string;
  vatNumber?: string;
  payrollLogo?: object;
  title: string;
  leadContactFirstName: string;
  leadContactLastName: string;
  leadContactEmail: string;
  leadContactJobTitle?: string;
  leadContactMobilePhoneNumber: string;
  leadContactWorkPhoneNumber?: string;
  leadContactFax?: string;
  website?: string;
  account?: string;
  employmentType?: string;
  standardWorkingHoursPerDay?: string;
  address?: CreateAddressDto;
  bank?: CreateBankDto;
  userId: string;
}

export interface UpdatePayrollDto {
  payrollProviderName?: string;
  vatNumber?: string;
  payrollLogo?: object;
  title?: string;
  leadContactFirstName?: string;
  leadContactLastName?: string;
  leadContactEmail?: string;
  leadContactJobTitle?: string;
  leadContactMobilePhoneNumber?: string;
  leadContactWorkPhoneNumber?: string;
  leadContactFax?: string;
  website?: string;
  account?: string;
  employmentType?: string;
  standardWorkingHoursPerDay?: string;
  address?: CreateAddressDto;
  bank?: CreateBankDto;
  userId?: string;
}

export enum EFrequencyTimesheet {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
}

export interface TimesheetSettings {
  createdAt?: string;
  deletedAt?: string;
  deletedBy?: string;
  createdBy?: string;
  actionByUserId?: string;
  _id: string;
  frequency: EFrequencyTimesheet;
  dayOfTheWeek: number;
  time: string;
  user: User;
}

export interface UpdateTimesheetSettingsDTO {
  frequency: EFrequencyTimesheet;
  dayOfTheWeek?: number;
  time: string;
}

export interface AddTimingsDto {
  /** @format date-time */
  date: string;
  startTime: string;
  endTime: string;
  callActiveHours: number;
  callPassiveHours: number;
  breakTime: number;
}

export interface AddTimingsArrayDto {
  timings: AddTimingsDto[];
}

export interface ApproveDto {
  signature: string;
}

export interface CreateInvoiceDto {
  placementId: string;
  timesheetId: string;
}

export interface WhatsappCandidatesCRMChat {
  _id: string;
  message: string;
  hostedFile?: HostedFile;
  timestamp: object;
  isRead?: boolean;
  isReply: boolean;
  candidate: Candidate;
}

export interface ChatListItemDto {
  member: Candidate | Client;
  lastMessage: string;
  /** @format date-time */
  lastMessageTimestamp: string;
  unreadMessagesCount: number;
  isReply: boolean;
}

export interface ReadChatDto {
  memberId: string;
}

export enum MEMBER_TYPE {
  Candidate = 'candidate',
  Client = 'client',
}

export interface SendMessageDto {
  /** Candidate/Client id */
  recipientId: string;
  recipientType: MEMBER_TYPE;
  message: string;
  /** @format binary */
  file: File;
}

export enum PORTALS {
  Crm = 'crm',
  Compliance = 'compliance',
}

export interface ReceiveMessageDto {
  SmsMessageSid: string;
  NumMedia: string;
  ProfileName: string;
  SmsSid: string;
  WaId: string;
  SmsStatus: string;
  Body: string;
  To: string;
  NumSegments: string;
  ReferralNumMedia: string;
  MessageSid: string;
  AccountSid: object;
  From: string;
  ApiVersion: string;
  MediaContentType0: string;
  MediaUrl0: string;
}

export interface CreateRateClientDto {
  type: EChargeRateType;
  specialty?: ESpecialty;
  coreHours: WeekWorkingHoursDto;
  pricing?: PricingPerGradeDto[];
}

export interface CreateClientDto {
  clientName: string;
  trustId?: string;
  title: string;
  firstName: string;
  lastName: string;
  jobTitle?: string;
  email: string;
  phoneNumber: string;
  workPhoneNumber?: string;
  faxNumber?: string;
  website?: string;
  document?: string;
  address?: CreateAddressDto;
  userId: string;
  bank?: CreateBankDto;
}

export interface UpdateClientDto {
  clientName?: string;
  trustId?: string;
  title?: string;
  firstName?: string;
  lastName?: string;
  jobTitle?: string;
  email?: string;
  phoneNumber?: string;
  workPhoneNumber?: string;
  faxNumber?: string;
  website?: string;
  document?: string;
  address?: CreateAddressDto;
  userId?: string;
  bank?: CreateBankDto;
}

export interface CreateDepartmentClientDto {
  titleContact: string;
  firstNameContact: string;
  lastNameContact: string;
  departmentContact?: string;
  teamContact: string;
  jobTitleContact: string;
  emailContact: string;
  phoneNumberContact: string;
  workPhoneNumberContact?: string;
  document?: string;
  titleAccount?: string;
  firstNameAccount?: string;
  lastNameAccount?: string;
  jobTitleAccount?: string;
  emailAccount?: string;
  phoneNumberAccount?: string;
  workPhoneNumberAccount?: string;
  bank: CreateBankDto;
  specialty: ESpecialty;
}

export interface CreateTrustDto {
  name: string;
  description?: string;
}

export interface UpdateTrustDto {
  name?: string;
  description?: string;
}

export interface Company {
  createdAt?: string;
  deletedAt?: string;
  deletedBy?: string;
  createdBy?: string;
  actionByUserId?: string;
  _id: string;
  name: string;
  website: string;
  vat: string;
  address: Address;
  title: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  email: string;
  phoneNumber: string;
  bank: Bank;
}

export interface CreateCompanyDto {
  name: string;
  vat: string;
  title: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  email: string;
  phoneNumber: string;
  website: string;
  address: CreateAddressDto;
  bank: CreateBankDto;
}

export enum EWebsocketType {
  Select = 'select',
  Message = 'message',
  Notification = 'notification',
  Email = 'email',
  Connect = 'connect',
  Info = 'info',
  Error = 'error',
  Warning = 'warning',
  Success = 'success',
}

export enum SocialPlatforms {
  Whatsapp = 'whatsapp',
  Email = 'email',
}

export interface SocketMessageEvent {
  member: Candidate | Client;
  memberType: MEMBER_TYPE;
  userId: string;
  message?: string;
  file?: HostedFile;
  files?: HostedFile[];
}

export interface SocketEmailEvent {
  userId: string;
  from: string;
  subject: string;
  messageId: string;
}

export interface EmailAttachment {
  filename: string;
  mimeType: string;
  data: string;
}

export interface SampleDto {
  websocketType: EWebsocketType;
  portals: PORTALS;
  socialPlatforms: SocialPlatforms;
  socketMessageEvent: SocketMessageEvent;
  socketEmailEvent: SocketEmailEvent;
  emailAttachment: EmailAttachment;
  complianceFile: ComplianceFile;
}

export interface WhatsappClientsCRMChat {
  _id: string;
  message: string;
  hostedFile?: HostedFile;
  timestamp: object;
  isRead?: boolean;
  isReply: boolean;
  client: object;
}

export interface EmailsCandidatesCRM {
  _id: string;
  isReply: boolean;
  subject: string;
  body: string;
  hostedFiles: HostedFile[];
  /** @format date-time */
  timestamp: string;
  isRead: boolean;
  isDraft: boolean;
  candidate: Candidate;
}

export interface EmailsClientsCRM {
  _id: string;
  isReply: boolean;
  subject: string;
  body: string;
  hostedFiles: HostedFile[];
  /** @format date-time */
  timestamp: string;
  isRead: boolean;
  isDraft: boolean;
  user?: User;
  client: Client;
}

export enum ETimePeriod {
  Today = 'Today',
  LastWeek = 'LastWeek',
  LastMonth = 'LastMonth',
  LastSixMonths = 'LastSixMonths',
  LastYear = 'LastYear',
  AllTime = 'AllTime',
}

export interface CandidatesStatistics {
  newlyCreated: number;
}

export interface ComplianceStatistics {
  IN_PROGRESS: number;
  PASSED: number;
  FAILED: number;
  EXPIRED: number;
}

export interface VacanciesStatistics {
  newlyCreated: number;
  hired: number;
  pending: number;
  active: number;
  closed: number;
  Filled: number;
  Unfilled: number;
}

export interface Statistics {
  createdAt?: string;
  deletedAt?: string;
  deletedBy?: string;
  createdBy?: string;
  actionByUserId?: string;
  _id: string;
  user: User;
  CandidatesStats: CandidatesStatistics;
  ComplianceStats: ComplianceStatistics;
  VacanciesStatistics: VacanciesStatistics;
  VacStatusSpecialtiesStats: object;
}

export interface LiveStats {
  incompleteProfiles: number;
  placementsWithIncompleteProfiles: number;
  clientsWithIncompleteProfiles: number;
  payrollsWithIncompleteProfiles: number;
}

export interface DashboardStatistics {
  dashboard: Statistics;
  liveStats: LiveStats;
}

export interface HistoryLog {
  createdAt?: string;
  deletedAt?: string;
  deletedBy?: string;
  createdBy?: string;
  actionByUserId?: string;
  _id: string;
  /** @format date-time */
  actionDate: string;
  doneBy: User;
  entityName: string;
  entityId: string;
  actionType: string;
  data: object;
  highlights: string[];
}

export enum EModuleNames {
  Users = 'users',
  PayrollExportSettings = 'payrollExportSettings',
  PayrollExports = 'payrollExports',
  Resumes = 'resumes',
  Invoices = 'invoices',
  Company = 'company',
  Department = 'department',
  Clients = 'clients',
  ClientHostedFiles = 'clientHostedFiles',
  CandidateHostedFiles = 'candidateHostedFiles',
  PayrollFiles = 'payrollFiles',
  EmailsCandidatesCRM = 'emailsCandidatesCRM',
  EmailsLogsClients = 'emailsLogsClients',
  WhatsappCandidatesCRMChat = 'whatsappCandidatesCRMChat',
  WhatsappClientsCRMChat = 'whatsappClientsCRMChat',
  TelegramLogs = 'telegramLogs',
  Applications = 'applications',
  Payrolls = 'payrolls',
  Candidates = 'candidates',
  Campaigns = 'campaigns',
  Jobs = 'jobs',
  TimesheetSettings = 'timesheetSettings',
  Timesheets = 'timesheets',
  Compliances = 'compliances',
  Availability = 'availability',
  HistoryLogs = 'historyLogs',
  Notifications = 'notifications',
  ComplianceFiles = 'complianceFiles',
  HostedFiles = 'hostedFiles',
  CandidateComplianceStatus = 'candidateComplianceStatus',
}

export interface CreateResumeDto {
  document: object;
  coverLetter?: object;
  photo?: object;
  dateCaptured?: string;
  candidateId: string;
}

export interface Resume {
  createdAt?: string;
  deletedAt?: string;
  deletedBy?: string;
  createdBy?: string;
  actionByUserId?: string;
  _id: string;
  document: object;
  coverLetter?: object;
  photo?: object;
  dateCaptured?: string;
  candidate: object;
}

export enum EChannel {
  Whatsapp = 'Whatsapp',
  Email = 'Email',
}

export enum EFrequency {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
}

export interface CreateCampaignDto {
  name: string;
  channel: EChannel;
  frequency?: EFrequency;
  /** @format date-time */
  startDate: string;
  runningTime: string;
  campaignTemplateId: string;
}

export interface CampaignTemplate {
  createdAt?: string;
  deletedAt?: string;
  deletedBy?: string;
  createdBy?: string;
  actionByUserId?: string;
  _id: string;
  name: string;
  message: string;
}

export interface Campaign {
  createdAt?: string;
  deletedAt?: string;
  deletedBy?: string;
  createdBy?: string;
  actionByUserId?: string;
  _id: string;
  name: string;
  channel: EChannel;
  frequency?: EFrequency;
  /** @format date-time */
  startDate: string;
  runningTime: string;
  isActive: boolean;
  user: User;
  template: CampaignTemplate;
}

export interface UpdateCampaignDto {
  name?: string;
  channel?: EChannel;
  frequency?: EFrequency;
  /** @format date-time */
  startDate?: string;
  runningTime?: string;
  campaignTemplateId?: string;
  isActive?: boolean;
}

export interface CreateCampaignTemplateDto {
  name: string;
  message: string;
}

export interface UpdateCampaignTemplateDto {
  name?: string;
  message?: string;
}

export interface PayrollExport {
  createdAt?: string;
  deletedAt?: string;
  deletedBy?: string;
  createdBy?: string;
  actionByUserId?: string;
  _id: string;
  timesheets: Timesheet[];
  /** @format date-time */
  createdDate: string;
  referenceNumber: number;
  status: string;
  payroll: Payroll;
}

export interface PayrollExportSettings {
  _id: string;
  frequency: EFrequencyTimesheet;
  dayOfTheWeek: number;
  time: string;
}

export type CreateOrderDto = object;

export interface CreateGeneralLeadDto {
  status: ELeadStatus;
  tag: ELeadTag;
  name: string;
  strength: ELeadStrength;
  specialty: ESpecialty;
  type: ELeadType;
  cpmIds: string[];
  region: string;
  trustId?: string;
  hospitalId: string;
  overview?: string;
  resourceRequirement: string;
  startingMonth: EMonth;
  durationValue: number;
  durationType: ELeadDurationType;
}

export interface AddFinancialLeadDto {
  revenue: number;
  cost: number;
  margin: number;
  benchmark: number;
}

export interface AddLegalLeadDto {
  legalContactId: string;
}

export interface UpdateLeadDto {
  status?: ELeadStatus;
  tag?: ELeadTag;
  name?: string;
  strength?: ELeadStrength;
  specialty?: ESpecialty;
  type?: ELeadType;
  cpmIds?: string[];
  region?: string;
  trustId?: string;
  hospitalId?: string;
  overview?: string;
  resourceRequirement?: string;
  startingMonth?: EMonth;
  durationValue?: number;
  durationType?: ELeadDurationType;
  revenue?: number;
  cost?: number;
  margin?: number;
  benchmark?: number;
  legalContactId?: string;
}

export interface ReportingResultDto {
  totalRevenue: number;
  totalForecastRevenue: number;
  totalCharge: number;
  grossMargin: number;
  grossMarginPercentage: number;
  week: number;
}

export interface TotalChargeByWeekDto {
  weeks: Record<string, object>;
}

export interface CreateGeneralProjectDto {
  name: string;
  specialty: string;
  cpmIds: string[];
  serviceCoordinatorId: string;
  cemId: string;
  trustId?: string;
  clientId: string;
  region: string;
  report: string;
}

export interface RoleActivityProjectDto {
  level: EJobLevel;
  grade: ENhsPayGrade;
  startTime: string;
  endTime: string;
  rate: number;
  expanse: number;
}

export interface ActivityProjectDto {
  subSpecialty: ESubSpecialty;
  activityType: EProjectActivityType;
  startDate: string;
  endDate: string;
  clientDepartmentId: string;
  rateType: EProjectActivityRateType;
  rate: number;
  template: number;
  bcv: number;
  roles: RoleActivityProjectDto[];
}

export interface AddActivitiesProjectDto {
  activities: ActivityProjectDto[];
}

export interface AddFinancialProjectDto {
  contactId: string;
  revenuePer: string;
  revenue: number;
  cost: number;
  margin: number;
  purchaseOrderNumber: number;
  invoicingTo: string;
  invoicingCC: string;
  staffPayFrequency: string;
  paymentTerms: string;
  invoiceFrequency: string;
}

export interface AddLegalProjectDto {
  legalContactId: string;
}

export interface UpdateProjectDto {
  name?: string;
  specialty?: string;
  cpmIds?: string[];
  serviceCoordinatorId?: string;
  cemId?: string;
  trustId?: string;
  clientId?: string;
  region?: string;
  report?: string;
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from 'axios';
import axios from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '' });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input;
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Careo API
 * @version 1.0
 * @contact
 *
 * API description
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  notifications = {
    /**
     * No description
     *
     * @tags Notifications
     * @name NotificationsControllerFindOne
     * @request GET:/notifications/{id}
     * @secure
     */
    notificationsControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<Notification, any>({
        path: `/notifications/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notifications
     * @name NotificationsControllerFindAll
     * @request GET:/notifications
     * @secure
     */
    notificationsControllerFindAll: (
      query?: {
        /**
         * Page number
         * @example 1
         */
        page?: number;
        /**
         * Page size
         * @example 10
         */
        pageSize?: number;
        bypassPagination?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          items: Notification[];
          /** @example 1 */
          page: number;
          /** @example 10 */
          pagesCount: number;
          /** @example 100 */
          documentsCount: number;
        },
        any
      >({
        path: `/notifications`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notifications
     * @name NotificationsControllerHideNotifications
     * @request PUT:/notifications/hide-notification
     * @secure
     */
    notificationsControllerHideNotifications: (data: HideNotificationDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/notifications/hide-notification`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notifications
     * @name NotificationsControllerViewNotification
     * @request PUT:/notifications/{id}/view
     * @secure
     */
    notificationsControllerViewNotification: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/notifications/${id}/view`,
        method: 'PUT',
        secure: true,
        ...params,
      }),
  };
  candidates = {
    /**
     * No description
     *
     * @tags Candidates
     * @name CandidatesControllerCreateCandidate
     * @request POST:/candidates
     * @secure
     */
    candidatesControllerCreateCandidate: (data: CreateCandidateDto, params: RequestParams = {}) =>
      this.request<any, Candidate>({
        path: `/candidates`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidates
     * @name CandidatesControllerFindAll
     * @request GET:/candidates
     * @secure
     */
    candidatesControllerFindAll: (
      query?: {
        /**
         * Page number
         * @example 1
         */
        page?: number;
        /**
         * Page size
         * @example 10
         */
        pageSize?: number;
        bypassPagination?: boolean;
        analytics?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          items: Candidate[];
          /** @example 1 */
          page: number;
          /** @example 10 */
          pagesCount: number;
          /** @example 100 */
          documentsCount: number;
        },
        any
      >({
        path: `/candidates`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidates
     * @name CandidatesControllerUploadCandidates
     * @request POST:/candidates/upload-csv
     * @secure
     */
    candidatesControllerUploadCandidates: (params: RequestParams = {}) =>
      this.request<any, Candidate>({
        path: `/candidates/upload-csv`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidates
     * @name CandidatesControllerGetCandidate
     * @request GET:/candidates/{id}
     * @secure
     */
    candidatesControllerGetCandidate: (
      id: string,
      query?: {
        /** Include analytics data */
        analytics?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, Candidate>({
        path: `/candidates/${id}`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidates
     * @name CandidatesControllerUpdate
     * @request PUT:/candidates/{id}
     * @secure
     */
    candidatesControllerUpdate: (id: string, data: UpdateCandidateDto, params: RequestParams = {}) =>
      this.request<any, ExtendedCandidate>({
        path: `/candidates/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidates
     * @name CandidatesControllerDeleteCandidate
     * @request DELETE:/candidates/{id}
     * @secure
     */
    candidatesControllerDeleteCandidate: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/candidates/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidates
     * @name CandidatesControllerCreateNoteCandidate
     * @request POST:/candidates/{id}/note
     * @secure
     */
    candidatesControllerCreateNoteCandidate: (id: string, data: CreateNoteCandidateDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/candidates/${id}/note`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidates
     * @name CandidatesControllerDeleteNoteCandidate
     * @request DELETE:/candidates/{id}/note/{idNote}
     * @secure
     */
    candidatesControllerDeleteNoteCandidate: (id: string, idNote: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/candidates/${id}/note/${idNote}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  };
  candidatesLists = {
    /**
     * No description
     *
     * @tags Candidates Lists (sharing lists)
     * @name CandidatesListsControllerCreate
     * @request POST:/candidates-lists
     * @secure
     */
    candidatesListsControllerCreate: (data: CreateCandidatesListDto, params: RequestParams = {}) =>
      this.request<any, CandidatesList>({
        path: `/candidates-lists`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidates Lists (sharing lists)
     * @name CandidatesListsControllerFindAll
     * @request GET:/candidates-lists
     * @secure
     */
    candidatesListsControllerFindAll: (
      query?: {
        /**
         * Page number
         * @example 1
         */
        page?: number;
        /**
         * Page size
         * @example 10
         */
        pageSize?: number;
        bypassPagination?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          items: CandidatesList[];
          /** @example 1 */
          page: number;
          /** @example 10 */
          pagesCount: number;
          /** @example 100 */
          documentsCount: number;
        },
        any
      >({
        path: `/candidates-lists`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidates Lists (sharing lists)
     * @name CandidatesListsControllerFindOne
     * @request GET:/candidates-lists/{listId}
     * @secure
     */
    candidatesListsControllerFindOne: (listId: string, params: RequestParams = {}) =>
      this.request<any, CandidatesList>({
        path: `/candidates-lists/${listId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidates Lists (sharing lists)
     * @name CandidatesListsControllerUpdate
     * @request PATCH:/candidates-lists/{listId}
     * @secure
     */
    candidatesListsControllerUpdate: (listId: string, data: UpdateCandidatesListDto, params: RequestParams = {}) =>
      this.request<any, CandidatesList>({
        path: `/candidates-lists/${listId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidates Lists (sharing lists)
     * @name CandidatesListsControllerRemove
     * @request DELETE:/candidates-lists/{listId}
     * @secure
     */
    candidatesListsControllerRemove: (listId: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/candidates-lists/${listId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidates Lists (sharing lists)
     * @name CandidatesListsControllerAddCandidate
     * @request POST:/candidates-lists/{listId}/add-candidate
     * @secure
     */
    candidatesListsControllerAddCandidate: (listId: string, params: RequestParams = {}) =>
      this.request<any, CandidatesList>({
        path: `/candidates-lists/${listId}/add-candidate`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidates Lists (sharing lists)
     * @name CandidatesListsControllerDisjoinList
     * @request POST:/candidates-lists/{listId}/disjoin
     * @secure
     */
    candidatesListsControllerDisjoinList: (listId: string, params: RequestParams = {}) =>
      this.request<any, CandidatesList>({
        path: `/candidates-lists/${listId}/disjoin`,
        method: 'POST',
        secure: true,
        ...params,
      }),
  };
  compliances = {
    /**
     * No description
     *
     * @tags Compliances
     * @name CompliancesControllerSetCompliancesByCandidateId
     * @request POST:/compliances/{candidateId}
     * @secure
     */
    compliancesControllerSetCompliancesByCandidateId: (
      candidateId: string,
      data: CreateComplianceListDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/compliances/${candidateId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Compliances
     * @name CompliancesControllerGetCompliance
     * @request GET:/compliances/{id}
     * @secure
     */
    compliancesControllerGetCompliance: (id: string, params: RequestParams = {}) =>
      this.request<Compliance, any>({
        path: `/compliances/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Compliances
     * @name CompliancesControllerUpdate
     * @request PUT:/compliances/{id}
     * @secure
     */
    compliancesControllerUpdate: (
      id: string,
      query: {
        validate: boolean;
      },
      data: UpdateComplianceDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/compliances/${id}`,
        method: 'PUT',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Compliances
     * @name CompliancesControllerDeleteCompliance
     * @request DELETE:/compliances/{id}
     * @secure
     */
    compliancesControllerDeleteCompliance: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/compliances/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Compliances
     * @name CompliancesControllerFindAll
     * @request GET:/compliances
     * @secure
     */
    compliancesControllerFindAll: (
      query?: {
        /**
         * Page number
         * @example 1
         */
        page?: number;
        /**
         * Page size
         * @example 10
         */
        pageSize?: number;
        bypassPagination?: boolean;
        candidateId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          items: Compliance[];
          /** @example 1 */
          page: number;
          /** @example 10 */
          pagesCount: number;
          /** @example 100 */
          documentsCount: number;
        },
        any
      >({
        path: `/compliances`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Compliances
     * @name CompliancesControllerGetUserAnalytics
     * @request GET:/compliances/data/analytics
     * @secure
     */
    compliancesControllerGetUserAnalytics: (params: RequestParams = {}) =>
      this.request<UserAnalytics, any>({
        path: `/compliances/data/analytics`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  files = {
    /**
     * No description
     *
     * @tags Files
     * @name FilesControllerUploadCandidateFile
     * @request POST:/files/upload/candidates/{candidateId}
     * @secure
     */
    filesControllerUploadCandidateFile: (
      candidateId: string,
      data: {
        /** @format binary */
        file?: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/files/upload/candidates/${candidateId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name FilesControllerUploadCandidateFiles
     * @request POST:/files/upload-multiple/candidates/{candidateId}
     * @secure
     */
    filesControllerUploadCandidateFiles: (
      candidateId: string,
      data: {
        /** @format binary */
        file?: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/files/upload-multiple/candidates/${candidateId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name FilesControllerUploadLeadFiles
     * @request POST:/files/upload-multiple/leads/{leadId}
     * @secure
     */
    filesControllerUploadLeadFiles: (
      leadId: string,
      data: {
        /** @format binary */
        file?: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/files/upload-multiple/leads/${leadId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name FilesControllerUploadProjectFiles
     * @request POST:/files/upload-multiple/projects/{projectId}
     * @secure
     */
    filesControllerUploadProjectFiles: (
      projectId: string,
      data: {
        /** @format binary */
        file?: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/files/upload-multiple/projects/${projectId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name FilesControllerUploadPlacementFiles
     * @request POST:/files/upload-multiple/placements/{placementId}
     * @secure
     */
    filesControllerUploadPlacementFiles: (
      placementId: string,
      data: {
        /** @format binary */
        file?: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/files/upload-multiple/placements/${placementId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name FilesControllerFindCandidateDocs
     * @request GET:/files/candidates/{candidateId}
     * @secure
     */
    filesControllerFindCandidateDocs: (
      candidateId: string,
      query?: {
        /**
         * Page number
         * @example 1
         */
        page?: number;
        /**
         * Page size
         * @example 10
         */
        pageSize?: number;
        bypassPagination?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          items: HostedFile[];
          /** @example 1 */
          page: number;
          /** @example 10 */
          pagesCount: number;
          /** @example 100 */
          documentsCount: number;
        },
        any
      >({
        path: `/files/candidates/${candidateId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name FilesControllerFindClientDocs
     * @request GET:/files/clients/{clientId}
     * @secure
     */
    filesControllerFindClientDocs: (clientId: string, params: RequestParams = {}) =>
      this.request<HostedFile[], any>({
        path: `/files/clients/${clientId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name FilesControllerFindPlacementDocs
     * @request GET:/files/placements/{placementId}
     * @secure
     */
    filesControllerFindPlacementDocs: (placementId: string, params: RequestParams = {}) =>
      this.request<HostedFile[], any>({
        path: `/files/placements/${placementId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name FilesControllerFindLeadDocuments
     * @request GET:/files/leads/{leadId}
     * @secure
     */
    filesControllerFindLeadDocuments: (leadId: string, params: RequestParams = {}) =>
      this.request<HostedFile[], any>({
        path: `/files/leads/${leadId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name FilesControllerFindProjectDocuments
     * @request GET:/files/projects/{projectId}
     * @secure
     */
    filesControllerFindProjectDocuments: (projectId: string, params: RequestParams = {}) =>
      this.request<HostedFile[], any>({
        path: `/files/projects/${projectId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name FilesControllerDeleteFile
     * @request DELETE:/files/{fileId}
     * @secure
     */
    filesControllerDeleteFile: (fileId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/files/${fileId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name FilesControllerRenameCandidateFile
     * @request PATCH:/files/rename/{fileIdOrKey}/{newFileName}
     * @secure
     */
    filesControllerRenameCandidateFile: (fileIdOrKey: string, newFileName: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/files/rename/${fileIdOrKey}/${newFileName}`,
        method: 'PATCH',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name FilesControllerDownloadFile
     * @request GET:/files/download/{fileId}
     * @secure
     */
    filesControllerDownloadFile: (fileId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/files/download/${fileId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name FilesControllerDownloadFileByTimesheetId
     * @request GET:/files/download/timesheet/{timesheetId}
     * @secure
     */
    filesControllerDownloadFileByTimesheetId: (timesheetId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/files/download/timesheet/${timesheetId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name FilesControllerDownloadFileByInvoiceId
     * @request GET:/files/download/invoice/{invoiceId}
     * @secure
     */
    filesControllerDownloadFileByInvoiceId: (invoiceId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/files/download/invoice/${invoiceId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  publicFiles = {
    /**
     * No description
     *
     * @tags Public Files
     * @name PublicFilesControllerDownloadFile
     * @request GET:/public-files/download/{fileId}
     */
    publicFilesControllerDownloadFile: (fileId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/public-files/download/${fileId}`,
        method: 'GET',
        ...params,
      }),
  };
  applications = {
    /**
     * No description
     *
     * @tags Applications
     * @name ApplicationsControllerCreate
     * @request POST:/applications
     * @secure
     */
    applicationsControllerCreate: (data: CreateApplicationDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/applications`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name ApplicationsControllerFindAll
     * @request GET:/applications
     * @secure
     */
    applicationsControllerFindAll: (
      query?: {
        /**
         * Page number
         * @example 1
         */
        page?: number;
        /**
         * Page size
         * @example 10
         */
        pageSize?: number;
        bypassPagination?: boolean;
        jobId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          items: Application[];
          /** @example 1 */
          page: number;
          /** @example 10 */
          pagesCount: number;
          /** @example 100 */
          documentsCount: number;
        },
        any
      >({
        path: `/applications`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name ApplicationsControllerGetApplicationById
     * @request GET:/applications/{id}
     * @secure
     */
    applicationsControllerGetApplicationById: (id: string, params: RequestParams = {}) =>
      this.request<any, Application>({
        path: `/applications/${id}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name ApplicationsControllerUpdateApplicationStatus
     * @request PUT:/applications/{id}
     * @secure
     */
    applicationsControllerUpdateApplicationStatus: (
      id: string,
      data: UpdateApplicationStatusDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/applications/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name ApplicationsControllerDeleteApplication
     * @request DELETE:/applications/{id}
     * @secure
     */
    applicationsControllerDeleteApplication: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/applications/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name ApplicationsControllerGetAllPlacements
     * @request GET:/applications/placements/list
     * @secure
     */
    applicationsControllerGetAllPlacements: (params: RequestParams = {}) =>
      this.request<Application[], any>({
        path: `/applications/placements/list`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name ApplicationsControllerValidatePlacement
     * @request PUT:/applications/{id}/validate
     * @secure
     */
    applicationsControllerValidatePlacement: (id: string, data: ValidatePlacementDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/applications/${id}/validate`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name ApplicationsControllerUpdateApplicationDuration
     * @request PATCH:/applications/{id}/update-application-duration
     * @secure
     */
    applicationsControllerUpdateApplicationDuration: (id: string, data: DurationDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/applications/${id}/update-application-duration`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  placementMember = {
    /**
     * No description
     *
     * @tags PlacementMember
     * @name PlacementMemberControllerFindOneByCandidateToken
     * @request GET:/placement-member/{token}/candidate
     */
    placementMemberControllerFindOneByCandidateToken: (token: string, params: RequestParams = {}) =>
      this.request<any, Application>({
        path: `/placement-member/${token}/candidate`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PlacementMember
     * @name PlacementMemberControllerFindOneByClientToken
     * @request GET:/placement-member/{token}/client
     */
    placementMemberControllerFindOneByClientToken: (token: string, params: RequestParams = {}) =>
      this.request<any, Application>({
        path: `/placement-member/${token}/client`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PlacementMember
     * @name PlacementMemberControllerCandidateConfirmByToken
     * @request PUT:/placement-member/{candidateToken}/candidate/approve
     */
    placementMemberControllerCandidateConfirmByToken: (candidateToken: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/placement-member/${candidateToken}/candidate/approve`,
        method: 'PUT',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PlacementMember
     * @name PlacementMemberControllerCandidateRejectByToken
     * @request PUT:/placement-member/{candidateToken}/candidate/reject
     */
    placementMemberControllerCandidateRejectByToken: (candidateToken: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/placement-member/${candidateToken}/candidate/reject`,
        method: 'PUT',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PlacementMember
     * @name PlacementMemberControllerClientConfirmByToken
     * @request PUT:/placement-member/{clientToken}/client/approve
     */
    placementMemberControllerClientConfirmByToken: (clientToken: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/placement-member/${clientToken}/client/approve`,
        method: 'PUT',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PlacementMember
     * @name PlacementMemberControllerClientRejectByToken
     * @request PUT:/placement-member/{clientToken}/client/reject
     */
    placementMemberControllerClientRejectByToken: (clientToken: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/placement-member/${clientToken}/client/reject`,
        method: 'PUT',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PlacementMember
     * @name PlacementMemberControllerRecruiterApproveByPlacementId
     * @request PUT:/placement-member/{placementId}/recruiter/approve
     * @secure
     */
    placementMemberControllerRecruiterApproveByPlacementId: (placementId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/placement-member/${placementId}/recruiter/approve`,
        method: 'PUT',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PlacementMember
     * @name PlacementMemberControllerRecruiterRejectByPlacementId
     * @request PUT:/placement-member/{placementId}/recruiter/reject
     * @secure
     */
    placementMemberControllerRecruiterRejectByPlacementId: (placementId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/placement-member/${placementId}/recruiter/reject`,
        method: 'PUT',
        secure: true,
        ...params,
      }),
  };
  availability = {
    /**
     * No description
     *
     * @tags Availability
     * @name AvailabilityControllerAddAvailability
     * @request POST:/availability
     * @secure
     */
    availabilityControllerAddAvailability: (data: AddAvailabilityDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/availability`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Availability
     * @name AvailabilityControllerFindAllByCandidateId
     * @request GET:/availability/candidates/{candidateId}
     * @secure
     */
    availabilityControllerFindAllByCandidateId: (candidateId: string, params: RequestParams = {}) =>
      this.request<Availability[], any>({
        path: `/availability/candidates/${candidateId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Availability
     * @name AvailabilityControllerGetAvailability
     * @request GET:/availability/{id}
     * @secure
     */
    availabilityControllerGetAvailability: (id: string, params: RequestParams = {}) =>
      this.request<Availability, any>({
        path: `/availability/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Availability
     * @name AvailabilityControllerDeleteById
     * @request DELETE:/availability/{id}
     * @secure
     */
    availabilityControllerDeleteById: (id: string, params: RequestParams = {}) =>
      this.request<Availability, any>({
        path: `/availability/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Availability
     * @name AvailabilityControllerDeleteAvailabilityByRangeDate
     * @request DELETE:/availability/by-range/date
     * @secure
     */
    availabilityControllerDeleteAvailabilityByRangeDate: (data: DeleteAvailabilityDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/availability/by-range/date`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  jobs = {
    /**
     * No description
     *
     * @tags Jobs
     * @name JobsControllerCreateJob
     * @request POST:/jobs
     * @secure
     */
    jobsControllerCreateJob: (data: CreateJobDto, params: RequestParams = {}) =>
      this.request<any, Job>({
        path: `/jobs`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jobs
     * @name JobsControllerFindAll
     * @request GET:/jobs
     * @secure
     */
    jobsControllerFindAll: (
      query?: {
        /**
         * Page number
         * @example 1
         */
        page?: number;
        /**
         * Page size
         * @example 10
         */
        pageSize?: number;
        bypassPagination?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          items: Job[];
          /** @example 1 */
          page: number;
          /** @example 10 */
          pagesCount: number;
          /** @example 100 */
          documentsCount: number;
        },
        any
      >({
        path: `/jobs`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jobs
     * @name JobsControllerUploadJobs
     * @request POST:/jobs/upload-csv
     * @secure
     */
    jobsControllerUploadJobs: (params: RequestParams = {}) =>
      this.request<any, Job>({
        path: `/jobs/upload-csv`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jobs
     * @name JobsControllerGetJob
     * @request GET:/jobs/{id}
     * @secure
     */
    jobsControllerGetJob: (id: string, params: RequestParams = {}) =>
      this.request<any, Job>({
        path: `/jobs/${id}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jobs
     * @name JobsControllerUpdateJobStatus
     * @request PUT:/jobs/{id}
     * @secure
     */
    jobsControllerUpdateJobStatus: (id: string, data: UpdateJobDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/jobs/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jobs
     * @name JobsControllerDeleteJob
     * @request DELETE:/jobs/{id}
     * @secure
     */
    jobsControllerDeleteJob: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/jobs/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jobs
     * @name JobsControllerUpdate
     * @request PUT:/jobs/{id}/update
     * @secure
     */
    jobsControllerUpdate: (id: string, data: UpdateJobDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/jobs/${id}/update`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  users = {
    /**
     * No description
     *
     * @tags Users
     * @name UsersControllerCreateUser
     * @request POST:/users
     * @secure
     */
    usersControllerCreateUser: (data: CreateUserDto, params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/users`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersControllerFindAll
     * @request GET:/users
     * @secure
     */
    usersControllerFindAll: (
      query?: {
        userRole?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<User[], any>({
        path: `/users`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersControllerUploadUsers
     * @request POST:/users/upload-csv
     * @secure
     */
    usersControllerUploadUsers: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/users/upload-csv`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersControllerGetUser
     * @request GET:/users/{id}
     * @secure
     */
    usersControllerGetUser: (id: string, params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/users/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersControllerUpdate
     * @request PUT:/users/{id}
     * @secure
     */
    usersControllerUpdate: (id: string, data: UpdateUserDto, params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/users/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersControllerDeleteUser
     * @request DELETE:/users/{id}
     * @secure
     */
    usersControllerDeleteUser: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/users/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  };
  auth = {
    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerSignIn
     * @request POST:/auth/signin
     */
    authControllerSignIn: (data: SignInDto, params: RequestParams = {}) =>
      this.request<SignInResponse, any>({
        path: `/auth/signin`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerRequestOtp
     * @request POST:/auth/request-otp
     */
    authControllerRequestOtp: (
      data: {
        email?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/auth/request-otp`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerVerifyOtp
     * @request POST:/auth/verify-otp
     */
    authControllerVerifyOtp: (data: VerifyOtpDto, params: RequestParams = {}) =>
      this.request<RefreshTokenResponse, any>({
        path: `/auth/verify-otp`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerRefreshToken
     * @request POST:/auth/refresh
     */
    authControllerRefreshToken: (params: RequestParams = {}) =>
      this.request<RefreshTokenResponse, any>({
        path: `/auth/refresh`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerVerifyToken
     * @request GET:/auth/verify-token
     * @secure
     */
    authControllerVerifyToken: (params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/auth/verify-token`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerLogout
     * @request POST:/auth/logout
     */
    authControllerLogout: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/auth/logout`,
        method: 'POST',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerForgetPassword
     * @request POST:/auth/forget-password
     */
    authControllerForgetPassword: (data: ForgetPasswordDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/auth/forget-password`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerResetPassword
     * @request POST:/auth/reset-password
     */
    authControllerResetPassword: (
      query: {
        token: string;
      },
      data: ResetPasswordDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/auth/reset-password`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  emails = {
    /**
     * No description
     *
     * @tags emails
     * @name EmailsControllerGetAuthUrl
     * @request GET:/emails/auth-url
     * @secure
     */
    emailsControllerGetAuthUrl: (
      query: {
        portal: string;
        provider: EMAIL_PROVIDERS;
      },
      params: RequestParams = {},
    ) =>
      this.request<AuthUrlResponseDto, any>({
        path: `/emails/auth-url`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags emails
     * @name EmailsControllerRevokeEmailLink
     * @request GET:/emails/revoke-link
     * @secure
     */
    emailsControllerRevokeEmailLink: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/emails/revoke-link`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags emails
     * @name EmailsControllerGetLabelsDetails
     * @request GET:/emails/get-labels-details
     * @secure
     */
    emailsControllerGetLabelsDetails: (params: RequestParams = {}) =>
      this.request<any, LabelsDetailsResponseDto[]>({
        path: `/emails/get-labels-details`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags emails
     * @name EmailsControllerFetchEmailMessageById
     * @request GET:/emails/{messageId}
     * @secure
     */
    emailsControllerFetchEmailMessageById: (messageId: string, params: RequestParams = {}) =>
      this.request<EmailBody, any>({
        path: `/emails/${messageId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags emails
     * @name EmailsControllerDeleteEmail
     * @request DELETE:/emails/{messageId}
     * @secure
     */
    emailsControllerDeleteEmail: (messageId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/emails/${messageId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags emails
     * @name EmailsControllerRestoreDeletedEmail
     * @request POST:/emails/{messageId}
     * @secure
     */
    emailsControllerRestoreDeletedEmail: (messageId: string, destinationId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/emails/${messageId}`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags emails
     * @name EmailsControllerMarkMessageAsRead
     * @request GET:/emails/mark-message-as-read/{messageId}
     * @secure
     */
    emailsControllerMarkMessageAsRead: (messageId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/emails/mark-message-as-read/${messageId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags emails
     * @name EmailsControllerGetUserEmails
     * @request GET:/emails
     * @secure
     */
    emailsControllerGetUserEmails: (
      query?: {
        /**
         * Page number
         * @example 1
         */
        pageToken?: string;
        /**
         * Page size
         * @example 10
         */
        pageSize?: number;
        /** Box */
        box?: BOX_TYPE;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserEmailsResponseDto, any>({
        path: `/emails`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags emails
     * @name EmailsControllerSendEmail
     * @request POST:/emails/send-email
     * @secure
     */
    emailsControllerSendEmail: (data: SendEmailDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/emails/send-email`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),
  };
  emailsDrafts = {
    /**
     * No description
     *
     * @tags emails-drafts
     * @name EmailDraftsControllerCreate
     * @request POST:/emails-drafts/create
     * @secure
     */
    emailDraftsControllerCreate: (data: CreateDraftDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/emails-drafts/create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags emails-drafts
     * @name EmailDraftsControllerFetchDraftMessageById
     * @request GET:/emails-drafts/{draftId}
     * @secure
     */
    emailDraftsControllerFetchDraftMessageById: (draftId: string, params: RequestParams = {}) =>
      this.request<EmailBody, any>({
        path: `/emails-drafts/${draftId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags emails-drafts
     * @name EmailDraftsControllerUpdateDraft
     * @request PATCH:/emails-drafts/{draftId}
     * @secure
     */
    emailDraftsControllerUpdateDraft: (draftId: string, data: UpdateDraftDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/emails-drafts/${draftId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags emails-drafts
     * @name EmailDraftsControllerDeleteDraftEmail
     * @request DELETE:/emails-drafts/{draftId}
     * @secure
     */
    emailDraftsControllerDeleteDraftEmail: (draftId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/emails-drafts/${draftId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags emails-drafts
     * @name EmailDraftsControllerSendDraftEmail
     * @request POST:/emails-drafts/{draftId}/send
     * @secure
     */
    emailDraftsControllerSendDraftEmail: (draftId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/emails-drafts/${draftId}/send`,
        method: 'POST',
        secure: true,
        ...params,
      }),
  };
  payrolls = {
    /**
     * No description
     *
     * @tags Payrolls
     * @name PayrollsControllerCreate
     * @request POST:/payrolls
     * @secure
     */
    payrollsControllerCreate: (data: CreatePayrollDto, params: RequestParams = {}) =>
      this.request<Payroll, any>({
        path: `/payrolls`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payrolls
     * @name PayrollsControllerFindAll
     * @request GET:/payrolls
     * @secure
     */
    payrollsControllerFindAll: (
      query?: {
        /**
         * Page number
         * @example 1
         */
        page?: number;
        /**
         * Page size
         * @example 10
         */
        pageSize?: number;
        bypassPagination?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          items: Payroll[];
          /** @example 1 */
          page: number;
          /** @example 10 */
          pagesCount: number;
          /** @example 100 */
          documentsCount: number;
        },
        any
      >({
        path: `/payrolls`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payrolls
     * @name PayrollsControllerUploadPayrolls
     * @request POST:/payrolls/upload-csv
     * @secure
     */
    payrollsControllerUploadPayrolls: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/payrolls/upload-csv`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payrolls
     * @name PayrollsControllerFindOne
     * @request GET:/payrolls/{id}
     * @secure
     */
    payrollsControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<Payroll, any>({
        path: `/payrolls/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payrolls
     * @name PayrollsControllerUpdate
     * @request PUT:/payrolls/{id}
     * @secure
     */
    payrollsControllerUpdate: (id: string, data: UpdatePayrollDto, params: RequestParams = {}) =>
      this.request<Payroll, any>({
        path: `/payrolls/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payrolls
     * @name PayrollsControllerDelete
     * @request DELETE:/payrolls/{id}
     * @secure
     */
    payrollsControllerDelete: (id: string, params: RequestParams = {}) =>
      this.request<Payroll, any>({
        path: `/payrolls/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  timesheets = {
    /**
     * No description
     *
     * @tags Timesheets
     * @name TimesheetsControllerFindOne
     * @request GET:/timesheets/{id}
     * @secure
     */
    timesheetsControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<any, Timesheet>({
        path: `/timesheets/${id}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Timesheets
     * @name TimesheetsControllerDelete
     * @request DELETE:/timesheets/{id}
     * @secure
     */
    timesheetsControllerDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/timesheets/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Timesheets
     * @name TimesheetsControllerGetTimesheetSettings
     * @request GET:/timesheets/settings/timesheet
     * @secure
     */
    timesheetsControllerGetTimesheetSettings: (params: RequestParams = {}) =>
      this.request<TimesheetSettings, any>({
        path: `/timesheets/settings/timesheet`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Timesheets
     * @name TimesheetsControllerFindAll
     * @request GET:/timesheets
     * @secure
     */
    timesheetsControllerFindAll: (
      query?: {
        /**
         * Page number
         * @example 1
         */
        page?: number;
        /**
         * Page size
         * @example 10
         */
        pageSize?: number;
        bypassPagination?: boolean;
        placementId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          items: Timesheet[];
          /** @example 1 */
          page: number;
          /** @example 10 */
          pagesCount: number;
          /** @example 100 */
          documentsCount: number;
        },
        any
      >({
        path: `/timesheets`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Timesheets
     * @name TimesheetsControllerUpdateTimesheetSettings
     * @request PATCH:/timesheets/settings
     * @secure
     */
    timesheetsControllerUpdateTimesheetSettings: (data: UpdateTimesheetSettingsDTO, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/timesheets/settings`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  timesheetMember = {
    /**
     * No description
     *
     * @tags TimesheetMember
     * @name TimesheetMemberControllerFindOneByToken
     * @request GET:/timesheet-member/{token}
     */
    timesheetMemberControllerFindOneByToken: (token: string, params: RequestParams = {}) =>
      this.request<any, Timesheet>({
        path: `/timesheet-member/${token}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags TimesheetMember
     * @name TimesheetMemberControllerAddTimingsByCandidateToken
     * @request PUT:/timesheet-member/{candidateToken}/add-timings
     */
    timesheetMemberControllerAddTimingsByCandidateToken: (
      candidateToken: string,
      data: AddTimingsArrayDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/timesheet-member/${candidateToken}/add-timings`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TimesheetMember
     * @name TimesheetMemberControllerApproveByClientToken
     * @request PUT:/timesheet-member/{clientToken}/approve
     */
    timesheetMemberControllerApproveByClientToken: (
      clientToken: string,
      data: ApproveDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/timesheet-member/${clientToken}/approve`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TimesheetMember
     * @name TimesheetMemberControllerRejectByClientToken
     * @request PUT:/timesheet-member/{clientToken}/reject
     */
    timesheetMemberControllerRejectByClientToken: (clientToken: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/timesheet-member/${clientToken}/reject`,
        method: 'PUT',
        ...params,
      }),
  };
  invoices = {
    /**
     * No description
     *
     * @tags Invoices
     * @name InvoicesControllerCreate
     * @request POST:/invoices
     * @secure
     */
    invoicesControllerCreate: (data: CreateInvoiceDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/invoices`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoices
     * @name InvoicesControllerFindAll
     * @request GET:/invoices
     * @secure
     */
    invoicesControllerFindAll: (
      query?: {
        /**
         * Page number
         * @example 1
         */
        page?: number;
        /**
         * Page size
         * @example 10
         */
        pageSize?: number;
        bypassPagination?: boolean;
        placementId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          items: Invoice[];
          /** @example 1 */
          page: number;
          /** @example 10 */
          pagesCount: number;
          /** @example 100 */
          documentsCount: number;
        },
        any
      >({
        path: `/invoices`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoices
     * @name InvoicesControllerFindOne
     * @request GET:/invoices/{id}
     * @secure
     */
    invoicesControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<any, Invoice>({
        path: `/invoices/${id}`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  socialPlatforms = {
    /**
     * No description
     *
     * @tags Social-Platforms
     * @name SocialPlatformsControllerFetchMemberChats
     * @request GET:/social-platforms/{platform}/{portal}/{memberType}/chats/{memberId}
     * @secure
     */
    socialPlatformsControllerFetchMemberChats: (
      platform: string,
      portal: string,
      memberType: string,
      memberId: string,
      params: RequestParams = {},
    ) =>
      this.request<WhatsappCandidatesCRMChat[], any>({
        path: `/social-platforms/${platform}/${portal}/${memberType}/chats/${memberId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Social-Platforms
     * @name SocialPlatformsControllerFetchChatList
     * @request GET:/social-platforms/{platform}/{portal}/{memberType}/chat-list
     * @secure
     */
    socialPlatformsControllerFetchChatList: (
      platform: string,
      portal: string,
      memberType: string,
      params: RequestParams = {},
    ) =>
      this.request<ChatListItemDto[], any>({
        path: `/social-platforms/${platform}/${portal}/${memberType}/chat-list`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Social-Platforms
     * @name SocialPlatformsControllerMarkChatAsRead
     * @request POST:/social-platforms/{platform}/{portal}/{memberType}/read-chat
     * @secure
     */
    socialPlatformsControllerMarkChatAsRead: (
      platform: string,
      portal: string,
      memberType: string,
      data: ReadChatDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/social-platforms/${platform}/${portal}/${memberType}/read-chat`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  whatsapp = {
    /**
     * No description
     *
     * @tags Whatsapp
     * @name WhatsappControllerSendMessage
     * @request POST:/whatsapp/{portal}/send-message
     * @secure
     */
    whatsappControllerSendMessage: (portal: string, data: SendMessageDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/whatsapp/${portal}/send-message`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Whatsapp
     * @name WhatsappControllerReceiveMessage
     * @request POST:/whatsapp/{portal}/receive-message
     */
    whatsappControllerReceiveMessage: (portal: PORTALS, data: ReceiveMessageDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/whatsapp/${portal}/receive-message`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Whatsapp
     * @name WhatsappControllerTestMessage
     * @request POST:/whatsapp/{portal}/test-message
     */
    whatsappControllerTestMessage: (portal: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/whatsapp/${portal}/test-message`,
        method: 'POST',
        ...params,
      }),
  };
  clients = {
    /**
     * No description
     *
     * @tags Clients
     * @name ClientsControllerUpdateRate
     * @request PUT:/clients/rate/{id}
     * @secure
     */
    clientsControllerUpdateRate: (id: string, data: CreateRateClientDto, params: RequestParams = {}) =>
      this.request<Client, any>({
        path: `/clients/rate/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Clients
     * @name ClientsControllerCreateClient
     * @request POST:/clients
     * @secure
     */
    clientsControllerCreateClient: (data: CreateClientDto, params: RequestParams = {}) =>
      this.request<Client, any>({
        path: `/clients`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Clients
     * @name ClientsControllerFindAll
     * @request GET:/clients
     * @secure
     */
    clientsControllerFindAll: (
      query?: {
        /**
         * Page number
         * @example 1
         */
        page?: number;
        /**
         * Page size
         * @example 10
         */
        pageSize?: number;
        bypassPagination?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          items: Client[];
          /** @example 1 */
          page: number;
          /** @example 10 */
          pagesCount: number;
          /** @example 100 */
          documentsCount: number;
        },
        any
      >({
        path: `/clients`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Clients
     * @name ClientsControllerUploadClients
     * @request POST:/clients/upload-csv
     * @secure
     */
    clientsControllerUploadClients: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/clients/upload-csv`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Clients
     * @name ClientsControllerGetClient
     * @request GET:/clients/{id}
     * @secure
     */
    clientsControllerGetClient: (id: string, params: RequestParams = {}) =>
      this.request<Client, any>({
        path: `/clients/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Clients
     * @name ClientsControllerUpdate
     * @request PUT:/clients/{id}
     * @secure
     */
    clientsControllerUpdate: (id: string, data: UpdateClientDto, params: RequestParams = {}) =>
      this.request<Client, any>({
        path: `/clients/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Clients
     * @name ClientsControllerDeleteClient
     * @request DELETE:/clients/{id}
     * @secure
     */
    clientsControllerDeleteClient: (id: string, params: RequestParams = {}) =>
      this.request<Client, any>({
        path: `/clients/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Clients
     * @name ClientsControllerAddDepartment
     * @request PUT:/clients/{id}/department
     * @secure
     */
    clientsControllerAddDepartment: (id: string, data: CreateDepartmentClientDto, params: RequestParams = {}) =>
      this.request<Client, any>({
        path: `/clients/${id}/department`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Clients
     * @name ClientsControllerAddRate
     * @request PUT:/clients/{id}/rate
     * @secure
     */
    clientsControllerAddRate: (id: string, data: CreateRateClientDto, params: RequestParams = {}) =>
      this.request<Client, any>({
        path: `/clients/${id}/rate`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Clients
     * @name ClientsControllerDeleteDepartment
     * @request DELETE:/clients/{id}/department/{idDepartment}
     * @secure
     */
    clientsControllerDeleteDepartment: (id: string, idDepartment: string, params: RequestParams = {}) =>
      this.request<Client, any>({
        path: `/clients/${id}/department/${idDepartment}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Clients
     * @name ClientsControllerDeleteRate
     * @request DELETE:/clients/{id}/rate/{idRate}
     * @secure
     */
    clientsControllerDeleteRate: (id: string, idRate: string, params: RequestParams = {}) =>
      this.request<Client, any>({
        path: `/clients/${id}/rate/${idRate}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  trust = {
    /**
     * No description
     *
     * @tags Trusts
     * @name TrustsControllerCreateTrust
     * @request POST:/trust
     * @secure
     */
    trustsControllerCreateTrust: (data: CreateTrustDto, params: RequestParams = {}) =>
      this.request<Trust, any>({
        path: `/trust`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Trusts
     * @name TrustsControllerFindAll
     * @request GET:/trust
     * @secure
     */
    trustsControllerFindAll: (
      query?: {
        /**
         * Page number
         * @example 1
         */
        page?: number;
        /**
         * Page size
         * @example 10
         */
        pageSize?: number;
        bypassPagination?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          items: Trust[];
          /** @example 1 */
          page: number;
          /** @example 10 */
          pagesCount: number;
          /** @example 100 */
          documentsCount: number;
        },
        any
      >({
        path: `/trust`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Trusts
     * @name TrustsControllerUploadCsv
     * @request POST:/trust/upload-csv
     * @secure
     */
    trustsControllerUploadCsv: (params: RequestParams = {}) =>
      this.request<Trust, any>({
        path: `/trust/upload-csv`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Trusts
     * @name TrustsControllerFindById
     * @request GET:/trust/{id}
     * @secure
     */
    trustsControllerFindById: (id: string, params: RequestParams = {}) =>
      this.request<Trust, any>({
        path: `/trust/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Trusts
     * @name TrustsControllerUpdateById
     * @request PUT:/trust/{id}
     * @secure
     */
    trustsControllerUpdateById: (id: string, data: UpdateTrustDto, params: RequestParams = {}) =>
      this.request<Trust, any>({
        path: `/trust/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Trusts
     * @name TrustsControllerDeleteById
     * @request DELETE:/trust/{id}
     * @secure
     */
    trustsControllerDeleteById: (id: string, params: RequestParams = {}) =>
      this.request<Trust, any>({
        path: `/trust/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  company = {
    /**
     * No description
     *
     * @tags Company
     * @name CompanyControllerFindOne
     * @request GET:/company
     * @secure
     */
    companyControllerFindOne: (params: RequestParams = {}) =>
      this.request<any, Company>({
        path: `/company`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name CompanyControllerUpdate
     * @request PUT:/company
     * @secure
     */
    companyControllerUpdate: (data: CreateCompanyDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/company`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  sample = {
    /**
     * No description
     *
     * @tags sample
     * @name SamplesControllerSample1
     * @request POST:/sample/sample1
     * @secure
     */
    samplesControllerSample1: (data: SampleDto, params: RequestParams = {}) =>
      this.request<any, WhatsappCandidatesCRMChat>({
        path: `/sample/sample1`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags sample
     * @name SamplesControllerSample2
     * @request POST:/sample/sample2
     * @secure
     */
    samplesControllerSample2: (params: RequestParams = {}) =>
      this.request<any, WhatsappClientsCRMChat>({
        path: `/sample/sample2`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags sample
     * @name SamplesControllerSample3
     * @request POST:/sample/sample3
     * @secure
     */
    samplesControllerSample3: (params: RequestParams = {}) =>
      this.request<any, EmailsCandidatesCRM>({
        path: `/sample/sample3`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags sample
     * @name SamplesControllerSample4
     * @request POST:/sample/sample4
     * @secure
     */
    samplesControllerSample4: (params: RequestParams = {}) =>
      this.request<any, EmailsClientsCRM>({
        path: `/sample/sample4`,
        method: 'POST',
        secure: true,
        ...params,
      }),
  };
  statistics = {
    /**
     * No description
     *
     * @tags Statistics
     * @name StatisticsControllerFetchDashboardStatistics
     * @request GET:/statistics
     * @secure
     */
    statisticsControllerFetchDashboardStatistics: (
      query: {
        timePeriod: ETimePeriod;
        fromDate?: string;
        toDate?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<DashboardStatistics, any>({
        path: `/statistics`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  historyLogs = {
    /**
     * No description
     *
     * @tags HistoryLogs
     * @name HistoryLogsControllerGetHistoryLog
     * @request GET:/historyLogs/{id}
     */
    historyLogsControllerGetHistoryLog: (id: string, params: RequestParams = {}) =>
      this.request<any, HistoryLog>({
        path: `/historyLogs/${id}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags HistoryLogs
     * @name HistoryLogsControllerDeleteHistoryLog
     * @request DELETE:/historyLogs/{id}
     */
    historyLogsControllerDeleteHistoryLog: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/historyLogs/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @tags HistoryLogs
     * @name HistoryLogsControllerGetAllHistoryLogs
     * @request GET:/historyLogs
     */
    historyLogsControllerGetAllHistoryLogs: (
      query: {
        /**
         * Page number
         * @example 1
         */
        page?: number;
        /**
         * Page size
         * @example 10
         */
        pageSize?: number;
        bypassPagination?: boolean;
        doneBy?: string;
        entityName: EModuleNames;
        entityId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          items: HistoryLog[];
          /** @example 1 */
          page: number;
          /** @example 10 */
          pagesCount: number;
          /** @example 100 */
          documentsCount: number;
        },
        any
      >({
        path: `/historyLogs`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  };
  resumes = {
    /**
     * No description
     *
     * @tags Resumes
     * @name ResumesControllerCreate
     * @request POST:/resumes
     * @secure
     */
    resumesControllerCreate: (data: CreateResumeDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/resumes`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Resumes
     * @name ResumesControllerFindAll
     * @request GET:/resumes
     * @secure
     */
    resumesControllerFindAll: (
      query?: {
        /**
         * Page number
         * @example 1
         */
        page?: number;
        /**
         * Page size
         * @example 10
         */
        pageSize?: number;
        bypassPagination?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          items: Resume[];
          /** @example 1 */
          page: number;
          /** @example 10 */
          pagesCount: number;
          /** @example 100 */
          documentsCount: number;
        },
        any
      >({
        path: `/resumes`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Resumes
     * @name ResumesControllerGetResume
     * @request GET:/resumes/{id}
     * @secure
     */
    resumesControllerGetResume: (id: string, params: RequestParams = {}) =>
      this.request<any, Resume>({
        path: `/resumes/${id}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Resumes
     * @name ResumesControllerDeleteResume
     * @request DELETE:/resumes/{id}
     * @secure
     */
    resumesControllerDeleteResume: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/resumes/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  };
  campaigns = {
    /**
     * No description
     *
     * @tags Campaigns
     * @name CampaignsControllerCreate
     * @request POST:/campaigns
     * @secure
     */
    campaignsControllerCreate: (data: CreateCampaignDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/campaigns`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Campaigns
     * @name CampaignsControllerFindAll
     * @request GET:/campaigns
     * @secure
     */
    campaignsControllerFindAll: (
      query?: {
        /**
         * Page number
         * @example 1
         */
        page?: number;
        /**
         * Page size
         * @example 10
         */
        pageSize?: number;
        bypassPagination?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          items: Campaign[];
          /** @example 1 */
          page: number;
          /** @example 10 */
          pagesCount: number;
          /** @example 100 */
          documentsCount: number;
        },
        any
      >({
        path: `/campaigns`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Campaigns
     * @name CampaignsControllerFindOne
     * @request GET:/campaigns/{id}
     * @secure
     */
    campaignsControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<any, Campaign>({
        path: `/campaigns/${id}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Campaigns
     * @name CampaignsControllerUpdate
     * @request PUT:/campaigns/{id}
     * @secure
     */
    campaignsControllerUpdate: (id: string, data: UpdateCampaignDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/campaigns/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Campaigns
     * @name CampaignsControllerDelete
     * @request DELETE:/campaigns/{id}
     * @secure
     */
    campaignsControllerDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/campaigns/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  };
  campaignTemplates = {
    /**
     * No description
     *
     * @tags CampaignTemplates
     * @name CampaignTemplatesControllerCreate
     * @request POST:/campaign-templates
     * @secure
     */
    campaignTemplatesControllerCreate: (data: CreateCampaignTemplateDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/campaign-templates`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CampaignTemplates
     * @name CampaignTemplatesControllerFindAll
     * @request GET:/campaign-templates
     * @secure
     */
    campaignTemplatesControllerFindAll: (
      query?: {
        /**
         * Page number
         * @example 1
         */
        page?: number;
        /**
         * Page size
         * @example 10
         */
        pageSize?: number;
        bypassPagination?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          items: CampaignTemplate[];
          /** @example 1 */
          page: number;
          /** @example 10 */
          pagesCount: number;
          /** @example 100 */
          documentsCount: number;
        },
        any
      >({
        path: `/campaign-templates`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CampaignTemplates
     * @name CampaignTemplatesControllerUpdate
     * @request PUT:/campaign-templates/{id}
     * @secure
     */
    campaignTemplatesControllerUpdate: (id: string, data: UpdateCampaignTemplateDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/campaign-templates/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CampaignTemplates
     * @name CampaignTemplatesControllerDelete
     * @request DELETE:/campaign-templates/{id}
     * @secure
     */
    campaignTemplatesControllerDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/campaign-templates/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  };
  payrollExports = {
    /**
     * No description
     *
     * @tags PayrollExports
     * @name PayrollExportsControllerGeneratePayrollExports
     * @request POST:/payrollExports
     * @secure
     */
    payrollExportsControllerGeneratePayrollExports: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/payrollExports`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PayrollExports
     * @name PayrollExportsControllerFindAll
     * @request GET:/payrollExports
     * @secure
     */
    payrollExportsControllerFindAll: (
      query?: {
        /**
         * Page number
         * @example 1
         */
        page?: number;
        /**
         * Page size
         * @example 10
         */
        pageSize?: number;
        bypassPagination?: boolean;
        payrollId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          items: PayrollExport[];
          /** @example 1 */
          page: number;
          /** @example 10 */
          pagesCount: number;
          /** @example 100 */
          documentsCount: number;
        },
        any
      >({
        path: `/payrollExports`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PayrollExports
     * @name PayrollExportsControllerFindOne
     * @request GET:/payrollExports/{id}
     * @secure
     */
    payrollExportsControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<any, PayrollExport>({
        path: `/payrollExports/${id}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PayrollExports
     * @name PayrollExportsControllerGetPayrollExportSettings
     * @request GET:/payrollExports/settings/payroll-export
     * @secure
     */
    payrollExportsControllerGetPayrollExportSettings: (params: RequestParams = {}) =>
      this.request<any, PayrollExportSettings>({
        path: `/payrollExports/settings/payroll-export`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PayrollExports
     * @name PayrollExportsControllerUpdatePayrollExportSettings
     * @request PATCH:/payrollExports/settings
     * @secure
     */
    payrollExportsControllerUpdatePayrollExportSettings: (
      data: UpdateTimesheetSettingsDTO,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/payrollExports/settings`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  payments = {
    /**
     * No description
     *
     * @name PaymentControllerCreateOrder
     * @request POST:/payments/create-order
     */
    paymentControllerCreateOrder: (data: CreateOrderDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/payments/create-order`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name PaymentControllerCaptureOrder
     * @request POST:/payments/capture/{orderId}
     */
    paymentControllerCaptureOrder: (orderId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/payments/capture/${orderId}`,
        method: 'POST',
        ...params,
      }),
  };
  webhooks = {
    /**
     * No description
     *
     * @name WebhooksControllerGmailWebhook
     * @request POST:/webhooks/gmail-webhook
     */
    webhooksControllerGmailWebhook: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/webhooks/gmail-webhook`,
        method: 'POST',
        ...params,
      }),

    /**
     * No description
     *
     * @name WebhooksControllerOutlookWebhook
     * @request POST:/webhooks/outlook-webhook
     */
    webhooksControllerOutlookWebhook: (
      query: {
        validationToken: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/webhooks/outlook-webhook`,
        method: 'POST',
        query: query,
        ...params,
      }),
  };
  leads = {
    /**
     * No description
     *
     * @tags Leads
     * @name LeadsControllerCreate
     * @request POST:/leads
     * @secure
     */
    leadsControllerCreate: (data: CreateGeneralLeadDto, params: RequestParams = {}) =>
      this.request<Lead, any>({
        path: `/leads`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Leads
     * @name LeadsControllerFindAll
     * @request GET:/leads
     * @secure
     */
    leadsControllerFindAll: (
      query?: {
        /**
         * Page number
         * @example 1
         */
        page?: number;
        /**
         * Page size
         * @example 10
         */
        pageSize?: number;
        bypassPagination?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          items: Lead[];
          /** @example 1 */
          page: number;
          /** @example 10 */
          pagesCount: number;
          /** @example 100 */
          documentsCount: number;
        },
        any
      >({
        path: `/leads`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Leads
     * @name LeadsControllerAddFinancialLeadyId
     * @request POST:/leads/{id}/add-financial-lead
     * @secure
     */
    leadsControllerAddFinancialLeadyId: (id: string, data: AddFinancialLeadDto, params: RequestParams = {}) =>
      this.request<Lead, any>({
        path: `/leads/${id}/add-financial-lead`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Leads
     * @name LeadsControllerAddLegalLeadyId
     * @request POST:/leads/{id}/add-legal-lead
     * @secure
     */
    leadsControllerAddLegalLeadyId: (id: string, data: AddLegalLeadDto, params: RequestParams = {}) =>
      this.request<Lead, any>({
        path: `/leads/${id}/add-legal-lead`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Leads
     * @name LeadsControllerFindById
     * @request GET:/leads/{id}
     * @secure
     */
    leadsControllerFindById: (id: string, params: RequestParams = {}) =>
      this.request<Lead, any>({
        path: `/leads/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Leads
     * @name LeadsControllerUpdateById
     * @request PUT:/leads/{id}
     * @secure
     */
    leadsControllerUpdateById: (id: string, data: UpdateLeadDto, params: RequestParams = {}) =>
      this.request<Lead, any>({
        path: `/leads/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Leads
     * @name LeadsControllerDeleteById
     * @request DELETE:/leads/{id}
     * @secure
     */
    leadsControllerDeleteById: (id: string, params: RequestParams = {}) =>
      this.request<Lead, any>({
        path: `/leads/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  projects = {
    /**
     * No description
     *
     * @tags Projects
     * @name ProjectsControllerReporting
     * @request GET:/projects/reporting
     * @secure
     */
    projectsControllerReporting: (params: RequestParams = {}) =>
      this.request<ReportingResultDto[], any>({
        path: `/projects/reporting`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Projects
     * @name ProjectsControllerTotalChargeByClientAndSpecialty
     * @request GET:/projects/reporting-total-charge
     * @secure
     */
    projectsControllerTotalChargeByClientAndSpecialty: (params: RequestParams = {}) =>
      this.request<TotalChargeByWeekDto, any>({
        path: `/projects/reporting-total-charge`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Projects
     * @name ProjectsControllerCreate
     * @request POST:/projects
     * @secure
     */
    projectsControllerCreate: (data: CreateGeneralProjectDto, params: RequestParams = {}) =>
      this.request<Project, any>({
        path: `/projects`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Projects
     * @name ProjectsControllerFindAll
     * @request GET:/projects
     * @secure
     */
    projectsControllerFindAll: (
      query?: {
        /**
         * Page number
         * @example 1
         */
        page?: number;
        /**
         * Page size
         * @example 10
         */
        pageSize?: number;
        bypassPagination?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          items: Project[];
          /** @example 1 */
          page: number;
          /** @example 10 */
          pagesCount: number;
          /** @example 100 */
          documentsCount: number;
        },
        any
      >({
        path: `/projects`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Projects
     * @name ProjectsControllerAddActivitiesProjectId
     * @request POST:/projects/{id}/add-activities-Project
     * @secure
     */
    projectsControllerAddActivitiesProjectId: (id: string, data: AddActivitiesProjectDto, params: RequestParams = {}) =>
      this.request<Project, any>({
        path: `/projects/${id}/add-activities-Project`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Projects
     * @name ProjectsControllerAddFinancialProjectId
     * @request POST:/projects/{id}/add-financial-Project
     * @secure
     */
    projectsControllerAddFinancialProjectId: (id: string, data: AddFinancialProjectDto, params: RequestParams = {}) =>
      this.request<Project, any>({
        path: `/projects/${id}/add-financial-Project`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Projects
     * @name ProjectsControllerAddLegalProjectId
     * @request POST:/projects/{id}/add-legal-Project
     * @secure
     */
    projectsControllerAddLegalProjectId: (id: string, data: AddLegalProjectDto, params: RequestParams = {}) =>
      this.request<Project, any>({
        path: `/projects/${id}/add-legal-Project`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Projects
     * @name ProjectsControllerFindById
     * @request GET:/projects/{id}
     * @secure
     */
    projectsControllerFindById: (id: string, params: RequestParams = {}) =>
      this.request<Project, any>({
        path: `/projects/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Projects
     * @name ProjectsControllerUpdateById
     * @request PUT:/projects/{id}
     * @secure
     */
    projectsControllerUpdateById: (id: string, data: UpdateProjectDto, params: RequestParams = {}) =>
      this.request<Project, any>({
        path: `/projects/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Projects
     * @name ProjectsControllerDeleteById
     * @request DELETE:/projects/{id}
     * @secure
     */
    projectsControllerDeleteById: (id: string, params: RequestParams = {}) =>
      this.request<Project, any>({
        path: `/projects/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
}
